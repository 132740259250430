var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { store } from "@redux/store";
import { API } from "constants/variables";
import { objectToQueryString, semverGreaterThan } from "constants/functions";
import { isObject } from "shared/helpers/typeGuards";
var APIError = /** @class */ (function (_super) {
    __extends(APIError, _super);
    function APIError(response, message) {
        var _this = _super.call(this, message || "".concat(response.status, " - ").concat(response.statusText)) || this;
        _this.name = _this.constructor.name;
        _this.status = response.status;
        _this.response = response;
        Object.setPrototypeOf(_this, APIError.prototype);
        return _this;
    }
    return APIError;
}(Error));
export { APIError };
// Requests that don't require an auth token
var WHITELISTED_REQUESTS = [
    "LOGIN_PREVIEW",
    "RELATED_PHOTOS_SAME",
    "RELATED_PHOTOS_OTHER",
    "SHARE_DOWNLOAD",
    "SUBMIT_MEDIA_BRIEF",
    "PLANOGRAM_PAGE",
    "SHARE_PAGE",
    "FEED_PHOTOS_EMBED",
    "USER_REFRESH_USING_SHELF_TOKEN",
    "ENTERPRISE_PORTAL",
    "USER_LOGS_IN",
    "USER_AUTH_VIA_SOCIAL_SIGN_ON",
    "GALLERY_PAGE",
    "GALLERY_PAGE_DECODE_TOKEN",
    "FLYER_GET_TAGS_FOR_VIEWER",
    "PHOTO_GET_TAGS_FOR_VIEWER",
    "CHECK_SSO_TLD_AND_GET_EMAIL_CODE",
];
var baseFetchOptions = {
    redirect: "follow",
    mode: "cors"
};
function getUrlPath(payload, dataUser) {
    var _a = dataUser !== null && dataUser !== void 0 ? dataUser : {}, userId = _a.id, shelf_token = _a.shelf_token, admin_impersonator_id = _a.admin_impersonator_id;
    var urlParams = __assign(__assign(__assign({}, payload.params), (payload.component !== "FEED_PHOTOS_EMBED" && {
        shelf_token: shelf_token,
        user_id: userId,
        client_id: 1,
    })), (admin_impersonator_id && {
        admin_impersonator_id: admin_impersonator_id
    }));
    return "".concat(payload.version || 1, "/").concat(payload.route, "?").concat(objectToQueryString(urlParams));
}
export function apiCall(payload) {
    return __awaiter(this, void 0, void 0, function () {
        var dataUser, fetchOptions, response, _a, _b, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    dataUser = store.getState().dataUser;
                    if (!(dataUser === null || dataUser === void 0 ? void 0 : dataUser.shelf_token) && !WHITELISTED_REQUESTS.includes(payload.component)) {
                        throw new Error("No token saved!");
                    }
                    fetchOptions = __assign(__assign({ method: payload.method, headers: __assign(__assign({}, (payload.method === "POST" && isObject(payload.body) && {
                            'Content-Type': 'application/json'
                        })), { "Accept": "application/json" }) }, (payload.method === "POST" && isObject(payload.body) && {
                        body: JSON.stringify(__assign({ user_id: dataUser === null || dataUser === void 0 ? void 0 : dataUser.id }, payload.body))
                    })), baseFetchOptions);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, fetch("".concat(API).concat(getUrlPath(payload, dataUser)), fetchOptions)];
                case 2:
                    response = _c.sent();
                    if (!!response.ok) return [3 /*break*/, 4];
                    _a = APIError.bind;
                    _b = [void 0, response];
                    return [4 /*yield*/, response.text()];
                case 3: throw new (_a.apply(APIError, _b.concat([_c.sent()])))();
                case 4: return [2 /*return*/, parseResponse(response, payload.responseType)];
                case 5:
                    error_1 = _c.sent();
                    if (error_1 instanceof APIError) {
                        throw error_1;
                    }
                    throw new Error("Network error or invalid JSON: ".concat(error_1));
                case 6: return [2 /*return*/];
            }
        });
    });
}
function parseResponse(response_1) {
    return __awaiter(this, arguments, void 0, function (response, responseType) {
        var versionBackend;
        if (responseType === void 0) { responseType = 'json'; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (responseType === 'json') {
                        versionBackend = response.headers.get("x-shelfgram-version");
                        if (semverGreaterThan(versionBackend, VERSION)) {
                            if (localStorage.getItem("versionBackend") !== versionBackend) {
                                localStorage.clear();
                                localStorage.setItem("versionBackend", versionBackend);
                                window.location.reload(true);
                            }
                        }
                    }
                    return [4 /*yield*/, response[responseType]()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
