var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import * as Sentry from "@sentry/react";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import createRootReducer from "reducers/root-reducer/index";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import { watcherSaga } from "reducers/sagas";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import { configureStore as configureStoreRTK } from "@reduxjs/toolkit";
import translationsReducer from "@redux/slices/translationsSlice";
// Persistance of REDUX to localStorage
var persistConfig = {
    key: "root",
    storage: storage,
    timeout: 0,
    blacklist: [
        "adminData",
        "automationRules",
        "checkEnterpriseTld",
        "dataAddToCollection",
        "dataAddToCollectionPhotoCheck",
        "dataDelete",
        "dataSearchByText",
        "dataDirectory",
        "dataCollectionsPublic",
        "dataCoverPhotoOptions",
        "dataCompanyCreate",
        "dataDirectorySettings",
        "dataEmbed",
        "dataFeed",
        "dataFeedSuggestionsLandingPage",
        "dataFeedUntagged",
        "dataFlyers",
        "dataFlyersSettings",
        "dataLandingPageAbout",
        "dataLandingPageAnalytics",
        "dataLandingPageRVI",
        "dataLandingPageRVISettings",
        "dataLandingPageLocations",
        "dataLoginPreview",
        "dataPendingUpload",
        "dataPreviewPhotoOptions",
        "dataReport",
        "dataRemoveFromCollection",
        "dataSearchQuery",
        "dataSelectorSearches",
        "dataShareLink",
        "dataSharePage",
        "dataGalleryPage",
        "dataTagger",
        "dataTagsSettings",
        "dataViewer",
        "dataViewerSettings",
        "feedFilterRules",
        "fetchReducer",
        "hawkeye",
        "industryReports",
        "industryReportsTopics",
        "loadingBar",
        "loginData",
        "mapZoom",
        "powerPointExportSettings",
        "router",
        "showCreateCompany",
        "showDropzone",
        "showFullscreenModal",
        "showModal",
        "showMenuDropdown",
        "showMainMenuDropdown",
        "showOffline",
        "showSearch",
        "showFeedNavMenu",
        "showFeedShareMenu",
        "showFeedShiftMultipleSelect",
        "showFeedSortMenu",
        "showFeedMoreMenu",
        "showUpload",
        "showHeader",
        "showHeatmap",
        "showSideMenu",
        "showTagging",
        "showViewer",
        "showViewerPog",
        "showUploadProgress",
        "studioSettings",
        "trends",
    ],
};
var _b = createReduxHistoryContext({ history: createBrowserHistory() }), createReduxHistory = _b.createReduxHistory, reduxFirstRouterMiddleware = _b.routerMiddleware, routerReducer = _b.routerReducer;
var rootReducer = combineReducers(__assign({ router: routerReducer, translations: translationsReducer }, createRootReducer()));
var sagaMiddleware = createSagaMiddleware();
var persistedReducer = persistReducer(persistConfig, rootReducer);
var sentryReduxEnhancer = Sentry.createReduxEnhancer({});
export function configureStore() {
    var middleware = __spreadArray([
        reduxFirstRouterMiddleware,
        sagaMiddleware
    ], (process.env.NODE_ENV !== 'production'
        ? [logger]
        : []), true);
    var store = configureStoreRTK({
        reducer: persistedReducer,
        middleware: function (getDefaultMiddleware) { return getDefaultMiddleware({ serializableCheck: false }).concat(middleware); },
        enhancers: function (getDefaultEnhancers) { return getDefaultEnhancers().concat(sentryReduxEnhancer); },
    });
    var history = createReduxHistory(store);
    var persistor = persistStore(store);
    return { store: store, history: history, persistor: persistor };
}
export var store = (_a = configureStore(), _a.store), history = _a.history, persistor = _a.persistor;
sagaMiddleware.run(watcherSaga);
