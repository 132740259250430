import { clone, cloneDeep } from "lodash";
import { EMPTY_QUESTION_CODE } from "components/question-code/QuestionCode.config";

const defaultRviSettings = {
  category_availability: { toggle: "main_section" },
  brand_support_by_retailer: {
    excludedRetailers: [],
  },
  brand_interaction: {
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  planogram_checks: {
    expanded_filter: "retailers",
    new: {},
    existing: {},
    resultsCount: null,
    filtersLastUpdated: null,
    showDatePicker: false,
    sort: "newest",
  },
  discoverability: {
    retailer: {
      name: "All Retailers",
      id: "all_retailers",
    },
  },
  leaders_by_geography: {
    toggle: "state",
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  leaders_by_retailer: {
    toggle: "table",
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  leaders_by_urbanization: {
    columnsToShow: {
      number: 10,
      id: "top_10",
      name: "Top 10",
    },
  },
  look_of_success: { toggle: "main_section" },
  anomaly_detection: { week: "latest" },
  national_visibility: {
    toggle: "overall",
    filters: {
      id: "rvi",
      name: "Overall",
    },
  },
  presence: {
    toggle: "main_section",
    week: "latest",
    columnsToShow: {
      number: 30,
      id: "top_30",
      name: "Top 30",
    },
  },
};

// reducers.js
const initialFetchReducerState = {
    fetching: false,
    error: null,
    component: null,
};

export const fetchReducer = (
  state = initialFetchReducerState,
  action
) => {
  switch (action.type) {
    case "PLANOGRAM_STITCH_IMAGES":
    case "API_CALL_REQUEST":
      return {
        ...state,
        fetching: true,
        error: null,
        component: action.payload?.component
      };
    case "API_CALL_TAKE_LATEST":
      return {
        ...state,
        fetching: true,
        error: null,
        component: action.payload?.component
      };
    case "API_CALL_SUCCESS":
      return {
        ...state,
        fetching: false,
        error: false,
        component: action.payload?.component
      };
    case "API_CALL_FAILURE":
      return {
        ...state,
        fetching: false,
        error: action.error,
        component: action.payload?.component
      };
    case "API_CALL_RESET":
      return state.component === action.payload
        ? initialFetchReducerState
        : state;
    default:
      return state;
  }
};

const defaultFeedMultiplePhotoSelect = {};

export const feedMultiplePhotoSelect = (
  state = defaultFeedMultiplePhotoSelect,
  action
) => {
  switch (action.type) {
    case "DELETE_DATA":
    case "GALLERY_PAGE_DATA":
    case "LOGOUT":
    case "RESET_SELECT_MULTIPLE_PHOTOS":
      return defaultFeedMultiplePhotoSelect;
    case "REMOVE_SPECIFIC_SELECTED_PHOTO":
      return {
        date: new Date(),
        data: state.data.filter((x, i) => i !== action.data),
      };
    case "SELECT_MULTIPLE_PHOTOS_ALL":
      return { date: new Date(), data: action.data };
    case "EDIT_SELECT_MULTIPLE_PHOTOS":
      if (!state.data || state.data.length == 0) {
        return { date: new Date(), data: [action.data] };
      } else {
        let strippedState = state.data || [];
        strippedState = strippedState.filter(
          (item) => item.id !== action.data.id
        );
        if (state.data && strippedState.length == state.data.length) {
          return { date: new Date(), data: strippedState.concat(action.data) };
        } else {
          return { date: new Date(), data: strippedState };
        }
      }
    default:
      return state;
  }
};

export const feedMultiplePlanogramSelect = (state = {}, action) => {
  switch (action.type) {
    case "RESET_SELECT_MULTIPLE_PLANOGRAMS":
    case "LOGOUT":
      return {};
    case "REMOVE_SPECIFIC_SELECTED_PLANOGRAM":
      return {
        date: new Date(),
        data: state.data.filter((x, i) => i !== action.data),
      };
    case "SELECT_MULTIPLE_PLANOGRAMS_ALL":
      return { date: new Date(), data: action.data };
    case "EDIT_SELECT_MULTIPLE_PLANOGRAMS":
      if (!state.data) {
        return { date: new Date(), data: [action.data] };
      } else {
        let strippedState = state.data || [];
        strippedState = strippedState.filter(
          (item) => item.id !== action.data.id
        );
        if (state.data && strippedState.length == state.data.length) {
          return { date: new Date(), data: strippedState.concat(action.data) };
        } else {
          return { date: new Date(), data: strippedState };
        }
      }
    default:
      return state;
  }
};

const defaultFeedMultipleFlyerSelect = {};

export const feedMultipleFlyerSelect = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_DATA":
    case "GALLERY_PAGE_DATA":
    case "LOGOUT":
    case "RESET_SELECT_MULTIPLE_FLYERS":
      return defaultFeedMultipleFlyerSelect;
    case "REMOVE_SPECIFIC_SELECTED_FLYER":
      return {
        date: new Date(),
        data: state.data.filter((x, i) => i !== action.data),
      };
    case "SELECT_MULTIPLE_FLYERS_ALL":
      return {
        date: new Date(),
        data: action.data,
      };
    case "EDIT_SELECT_MULTIPLE_FLYERS":
      if (!state.data || state.data.length == 0) {
        return { date: new Date(), data: [action.data] };
      } else {
        let strippedState = state.data || [];
        strippedState = strippedState.filter(
          (item) => item.id !== action.data.id
        );
        if (state.data && strippedState.length == state.data.length) {
          return { date: new Date(), data: strippedState.concat(action.data) };
        } else {
          return { date: new Date(), data: strippedState };
        }
      }
    default:
      return state;
  }
};

const defaultFeedFilterRules = {
  new: { filter: [] },
  existing: { filter: [] },
  query: { filter: [] },
  last_updated: new Date(),
  disabled: false,
  blur: false,
};

export const feedFilterRules = (state = defaultFeedFilterRules, action) => {
  switch (action.type) {
    case "FEED_PHOTOS_FILTERS_REMOVE":
      return {
        ...state,
        new: defaultFeedFilterRules.new,
      };
    case "FEED_PHOTOS_TRIGGER_RELOAD":
      return {
        ...state,
        trigger: `${new Date()}`,
      };
    case "FEED_FILTERS_BLUR":
      return { ...state, blur: true };
    case "HIDE_MODAL":
    case "FEED_FILTERS_FOCUS":
      return { ...state, blur: false };
    case "FEED_FILTERS_DISABLED":
      return { ...state, disabled: true };
    case "FEED_FILTERS_ENABLED":
      return { ...state, disabled: false };
    case "LOGOUT":
    case "FEED_PHOTOS_FILTERS_RESET":
      return defaultFeedFilterRules;
    case "SAVE_MAP_FILTER":
      let newFilters = cloneDeep(state.new.filter);
      newFilters[action.data.ruleIndex][action.data.conditionIndex].item = [
        {
          id: action.data.id,
          zoom: action.data.zoom,
          center: action.data.center,
        },
      ];
      newFilters[action.data.ruleIndex][action.data.conditionIndex].is = action
        .data.is
        ? "is"
        : "is_not";
      return Object.assign({}, state, {
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: newFilters,
        },
      });
    case "LAST_CHECKED_FILTER_IDS": {
      return {
        ...state,
        new: {
          ...state.new,
          filterIds: action.data,
        },
      };
    }
    case "APPLY_PREFETCHED_PHOTOS":
    case "RESET_FILTERS_CHECK_RESULTS_COUNT_PHOTOS":
      return {
        ...state,
        prefetchedData: null,
      };
    case "FILTERS_CHECK_RESULTS_COUNT_PHOTOS_DATA":
      const uniquePhotosCheck = [];
      const mapPhotosCheck = new Map();
      for (const itemPhotos of action.data.data) {
        if (!mapPhotosCheck.has(itemPhotos.id)) {
          mapPhotosCheck.set(itemPhotos.id, true); // set any value to Map
          uniquePhotosCheck.push(itemPhotos);
        }
      }
      return {
        ...state,
        prefetchedData: { ...action.data, data: uniquePhotosCheck },
      };
    case "CHECK_FILTERS_DATA":
      const newFilter = cloneDeep(state.new.filter);
      newFilter[action.data.rule_index][action.data.condition_index].whitelist =
        action.data.whitelist;
      return {
        ...state,
        new: {
          ...state.new,
          filter: newFilter,
        },
        last_updated: new Date(),
      };
    case "FEED_SETTINGS_PRESET":
      return Object.assign({}, state, {
        last_updated: new Date(),
        query: {
          filter: action.data.filter.map((rule) =>
            rule.map((condition) => ({
              is: condition.is,
              match: condition.match,
              endDate: condition.endDate,
              startDate: condition.startDate,
              ids: condition.item && condition.item.map((item) => item.id),
            }))
          ),
        },
        existing: {
          ...action.data,
        },
        new: {
          ...action.data,
        },
      });
    case "PHOTO_FILTERS_APPLY_NEW_CHANGES":
      const newChanges = cloneDeep(state.new);
      return Object.assign({}, state, {
        last_updated: new Date(),
        existing: newChanges,
        query: action.data,
      });
    case "FEED_FILTERS_CANCEL_NEW_CHANGES":
      const existing = cloneDeep(state.existing);
      return Object.assign({}, state, {
        last_updated: new Date(),
        prefetchedData: null,
        new: existing,
      });
    case "INIT_ONBOARDING_RULES":
      return {
        ...state,
        last_updated: new Date(),
        new: {
          sort: "newest",
          filter: [
            [
              {
                match: "categories",
                is: "is",
                item: [],
                id: 1,
              },
              {
                match: "regions",
                is: "is",
                item: [],
                id: 2,
              },
            ],
          ],
        },
      };
    case "ADD_EMPTY_FILTER_RULE":
      return {
        ...state,
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: state.new.filter.concat([
            [
              {
                match: null,
                is: "is",
                item: null,
                id: Date.now(),
              },
            ],
          ]),
        },
      };
    case "ADD_EMPTY_FILTER_CONDITION":
      const addCondition = cloneDeep(state.new.filter);
      addCondition[action.data.ruleIndex] = addCondition[
        action.data.ruleIndex
      ].concat([
        {
          match: null,
          is: "is",
          item: null,
          id: Date.now(),
        },
      ]);
      return {
        ...state,
        last_updated: new Date(),
        new: { ...state.new, filter: addCondition },
      };
    case "REMOVE_FILTER_CONDITION":
      let removeCondition = cloneDeep(state.new.filter);
      removeCondition[action.data.ruleIndex].splice(
        action.data.conditionIndex,
        1
      );
      if (removeCondition[action.data.ruleIndex].length == 0) {
        removeCondition.splice(action.data.ruleIndex, 1);
      }
      return Object.assign({}, state, {
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: removeCondition,
        },
      });
    case "FEED_FILTERS_CHANGE_SORT":
      const newSort = {
        ...state.new,
        sort: action.data,
      };
      return Object.assign({}, state, {
        last_updated: new Date(),
        existing: newSort,
        new: newSort,
        query: {
          ...state.query,
          sort: action.data,
        },
      });
    case "FILTER_CONDITION_SET_START_DATE":
      let editConditionStartDate = cloneDeep(state.new.filter);
      editConditionStartDate[action.data.ruleIndex][
        action.data.conditionIndex
      ].startDate = action.data.date;
      return Object.assign({}, state, {
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: editConditionStartDate,
        },
      });
    case "FILTER_CONDITION_SET_END_DATE":
      let editConditionEndDate = cloneDeep(state.new.filter);
      editConditionEndDate[action.data.ruleIndex][
        action.data.conditionIndex
      ].endDate = action.data.date;
      return Object.assign({}, state, {
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: editConditionEndDate,
        },
      });
    case "FILTER_CONDITION_CHANGE_LOGIC":
      let editConditionLogic = cloneDeep(state.new.filter);
      editConditionLogic[action.data.ruleIndex][action.data.conditionIndex].is =
        action.data.value;
      return Object.assign({}, state, {
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: editConditionLogic,
        },
      });
    case "FILTER_CONDITION_CHANGE_MATCH":
      let editConditionMatch = cloneDeep(state.new.filter);
      editConditionMatch[action.data.ruleIndex][
        action.data.conditionIndex
      ].match = action.data.value;
      editConditionMatch[action.data.ruleIndex][
        action.data.conditionIndex
      ].item = null;
      return Object.assign({}, state, {
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: editConditionMatch,
        },
      });
    case "FILTER_CONDITION_CHANGE_ITEM":
      let editConditionItem = cloneDeep(state.new.filter);
      editConditionItem[action.data.ruleIndex][
        action.data.conditionIndex
      ].item = action.data.item;
      editConditionItem[action.data.ruleIndex][
        action.data.conditionIndex
      ].action = "added";
      return Object.assign({}, state, {
        last_updated: new Date(),
        new: {
          ...state.new,
          filter: editConditionItem,
        },
      });
    default:
      return state;
  }
};

const defaultAutomationRules = {
  new: { filter: [], sort: "newest" },
  existing: { filter: [], sort: "newest" },
  query: { dimensions: [], filter: [], sort: "newest" },
  loaded: false,
};

export const automationRules = (state = defaultAutomationRules, action) => {
  switch (action.type) {
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return defaultAutomationRules;
    case "AUTOMATION_CONDITION_SET_START_DATE":
      let editConditionStartDate = cloneDeep(state.new.filter);
      editConditionStartDate[action.data.ruleIndex][
        action.data.conditionIndex
      ].startDate = action.data.date;
      return Object.assign({}, state, {
        new: { ...state.new, filter: editConditionStartDate },
      });
    case "AUTOMATION_CONDITION_SET_END_DATE":
      let editConditionEndDate = cloneDeep(state.new.filter);
      editConditionEndDate[action.data.ruleIndex][
        action.data.conditionIndex
      ].endDate = action.data.date;
      return Object.assign({}, state, {
        new: { ...state.new, filter: editConditionEndDate },
      });
    case "ADD_EMPTY_AUTOMATION_CONDITION":
      const addCondition = cloneDeep(state.new.filter);
      addCondition[action.data.ruleIndex] = addCondition[
        action.data.ruleIndex
      ].concat([
        {
          match: action.data.value,
          is: "is",
          item: null,
          id: Date.now(),
        },
      ]);
      return { ...state, new: { ...state.new, filter: addCondition } };
    case "ADD_EMPTY_AUTOMATION_RULE":
      if (state.new && state.new.filter) {
        return Object.assign({}, state, {
          noRules: false,
          new: {
            ...state.new,
            filter: state.new.filter.concat([
              [
                {
                  match: "categories",
                  is: "is",
                  item: null,
                  id: Date.now(),
                },
              ],
            ]),
          },
        });
      } else {
        return Object.assign({}, state, {
          noRules: false,
          new: {
            filter: [
              [
                {
                  match: "categories",
                  is: "is",
                  item: null,
                  id: Date.now(),
                },
              ],
            ],
          },
        });
      }
    case "AUTOMATION_RULES_DATA":
      if (action.data.json_data) {
        if (Array.isArray(action.data.json_data)) {
          return {
            existing: { filter: action.data.json_data },
            new: { filter: action.data.json_data },
            loaded: true,
          };
        } else {
          return {
            existing: action.data.json_data,
            new: action.data.json_data,
            loaded: true,
          };
        }
      }
      return {
        existing: null,
        new: null,
        noRules: true,
      };
    case "AUTOMATION_CONDITION_SAVE_NEW":
      let newData = cloneDeep(state.new.filter).map((rule) =>
        rule.filter((condition) => condition.item)
      );
      return Object.assign({}, state, {
        loaded: true,
        existing: { filter: newData },
      });
    case "AUTOMATION_CONDITION_CHANGE_ITEM":
      let editConditionItem = cloneDeep(state.new.filter);
      editConditionItem[action.data.ruleIndex][
        action.data.conditionIndex
      ].item = action.data.item;
      editConditionItem[action.data.ruleIndex][
        action.data.conditionIndex
      ].action = "added";
      return Object.assign({}, state, {
        new: { ...state.new, filter: editConditionItem },
      });
    case "AUTOMATION_CONDITION_CHANGE_MATCH":
      let editConditionMatch = cloneDeep(state.new.filter);
      editConditionMatch[action.data.ruleIndex][
        action.data.conditionIndex
      ].match = action.data.value;
      editConditionMatch[action.data.ruleIndex][
        action.data.conditionIndex
      ].item = null;
      return Object.assign({}, state, {
        new: { ...state.new, filter: editConditionMatch },
      });
    case "AUTOMATION_CONDITION_CHANGE_LOGIC":
      let editConditionLogic = cloneDeep(state.new.filter);
      editConditionLogic[action.data.ruleIndex][action.data.conditionIndex].is =
        action.data.value;
      return Object.assign({}, state, {
        new: { ...state.new, filter: editConditionLogic },
      });
    case "RESET_AUTOMATION_RULES":
      const existing = cloneDeep(state.existing);
      if (existing) {
        return Object.assign({}, state, { new: { ...state.existing } });
      } else {
        return {
          existing: null,
          new: null,
          noRules: true,
        };
      }
    case "REMOVE_AUTOMATION_CONDITION":
      let removeCondition = cloneDeep(state.new.filter);
      removeCondition[action.data.ruleIndex].splice(
        action.data.conditionIndex,
        1
      );
      if (removeCondition[action.data.ruleIndex].length == 0) {
        removeCondition.splice(action.data.ruleIndex, 1);
      }
      return Object.assign({}, state, {
        new: { ...state.new, filter: removeCondition },
      });
    default:
      return state;
  }
};

export const showOffline = (state = false, action) => {
  switch (action.type) {
    case "SHOW_OFFLINE":
      return true;
    case "HIDE_OFFLINE":
      return false;
    default:
      return state;
  }
};

export const showFeedShiftMultipleSelect = (state = false, action) => {
  switch (action.type) {
    case "SELECT_MULTIPLE_PLANOGRAMS_ALL":
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
      return true;
    case "@@router/LOCATION_CHANGE":
    case "DELETE_DATA":
    case "RESET_SELECT_MULTIPLE_PHOTOS":
    case "RESET_SELECT_MULTIPLE_FLYERS":
    case "HIDE_FEED_SHIFT_MULTIPLE_SELECT":
      return false;
    default:
      return state;
  }
};

export const showCreateCompany = (state = false, action) => {
  switch (action.type) {
    case "SHOW_CREATE_COMPANY":
      return true;
    case "HIDE_CREATE_COMPANY":
      return false;
    case "HIDE_MODAL":
      return false;
    default:
      return state;
  }
};

export const showHeader = (state = false, action) => {
  switch (action.type) {
    case "SHOW_HEADER":
      return true;
    case "LOGOUT":
    case "HIDE_HEADER":
      return false;
    default:
      return state;
  }
};

export const lastCreatedCollection = (state = null, action) => {
  switch (action.type) {
    case "COLLECTION_CREATE_DATA":
      return action.data;
    case "COLLECTION_CREATE_RESET_DATA":
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

export const showUploadProgress = (state = false, action) => {
  switch (action.type) {
    case "SHOW_UPLOAD_PROGRESS":
      return action.data;
    case "HIDE_UPLOAD_PROGRESS":
    case "UPLOAD_FILES_SEND_DATA_TO_API_DATA":
      return false;
    default:
      return state;
  }
};

export const showDropzone = (state = false, action) => {
  switch (action.type) {
    case "SHOW_DROPZONE":
      return true;
    case "HIDE_DROPZONE":
      return false;
    default:
      return state;
  }
};

export const showTagging = (state = false, action) => {
  switch (action.type) {
    case "SHOW_TAGGING":
      return true;
    case "VIEWER_SHOW_COLLECTIONS":
    case "VIEWER_SHOW_STUDIO":
    case "VIEWER_SHOW_COMMENTS":
    case "VIEWER_SHOW_MAP":
    case "VIEWER_SHOW_FULLSCREEN":
    case "SHOW_TEXT_ANNOTATIONS":
    case "SHOW_HEATMAP":
    case "HIDE_VIEWER":
    case "HIDE_TAGGING":
    case "LOGOUT":
      return false;
    default:
      return state;
  }
};

export const dataInitialLoad = (state = null, action) => {
  switch (action.type) {
    case "INITIAL_LOAD_DATA":
      return { ...action.data.data, last_updated: action.data.last_updated };
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

export const dataCompanyCreate = (state = null, action) => {
  switch (action.type) {
    case "CREATE_A_RETAILER_DATA":
      if (action.data && action.data.length > 0) {
        return action.data[0];
      }
      return null;
    case "CREATE_A_BRAND_DATA":
      return action.data[0];
    case "HIDE_MODAL":
      return null;
    default:
      return state;
  }
};

export const dataPipelineCollectionCreate = (state = null, action) => {
  switch (action.type) {
    case "CREATE_PIPELINE_COLLECTION_DATA":
      return action.data;
    case "CREATE_PIPELINE_COLLECTION_RESET_DATA":
    case "HIDE_MODAL":
      return null;
    default:
      return state;
  }
}

export const dataUser = (state = null, action) => {
  switch (action.type) {
    case "SHOW_BIG_ANNOUNCEMENT":
      return { ...state, big_announcement_seen: new Date() };
    case "WEBSOCKETS/update_pc_banner_message":
      return {
        ...state,
        pc_banner_message: action.data,
      };
    case "TOGGLE_HORIZONTAL_TIMELINE":
      return {
        ...state,
        timeline_horizontal: action.data,
      };
    case "CLEAR_NEW_NOTIFICATIONS":
      return { ...state, notifications: { unread: null, read: action.data } };
    case "WEBSOCKETS/add_notification":
      return {
        ...state,
        notifications: state.notifications
          ? {
              ...state.notifications,
              unread: state.notifications.unread
                ? [action.data].concat(state.notifications.unread)
                : [action.data],
            }
          : {
              unread: [action.data],
            },
      };
    case "USER_SELECT_HOMEPAGE_PREFERENCE":
      return { ...state, homepage_preference: action.data };
    case "TURN_ON_DARK_MODE":
      return { ...state, dark_mode: true };
    case "TURN_OFF_DARK_MODE":
      return { ...state, dark_mode: false };
    case "ADD_EMPTY_FILTER_CONDITION":
      return {
        ...state,
        shown_filter_how_to: true,
      };
    case "USER_CHECK_ACCESS_PLANOGRAMS_DATA":
      return {
        ...state,
        access_planograms: { last_updated: new Date(), data: action.data },
      };
    case "USER_CHECK_ACCESS_PRICE_CHECKS_DATA":
      return {
        ...state,
        access_pricing: { last_updated: new Date(), data: action.data },
      };
    case "USER_CHECK_ACCESS_ANALYTICS_DATA":
      return {
        ...state,
        access_analytics: { last_updated: new Date(), data: action.data },
      };
    case "STARRED_PAGES_DATA":
      return {
        ...state,
        starred: action.data,
      };
    case "STAR_CHECK_ON":
      let currentStars = cloneDeep(state.starred || []);
      currentStars.unshift(action.data);
      return {
        ...state,
        starred: currentStars,
      };
    case "STAR_BUTTON_CHECK_OFF":
    case "STAR_CHECK_OFF":
      return {
        ...state,
        starred: (state.starred || []).filter(
          (x) => x.pathname !== action.data
        ),
      };
    case "PUBLICATIONS_SET_SORT":
      return { ...state, publications_sort: action.data };
    case "USER_TURNS_OFF_PUBLISHER": {
      return {
        ...state,
        publishers: {
          ...state.publishers,
          excluded: state.publishers.excluded.concat([action.data]),
        },
      };
    }
    case "USER_TURNS_ON_PUBLISHER": {
      return {
        ...state,
        publishers: {
          ...state.publishers,
          excluded: state.publishers.excluded.filter((x) => x !== action.data),
        },
      };
    }
    case "USER_GET_PUBLISHERS_DATA": {
      return { ...state, publishers: action.data };
    }
    case "USER_SET_PPT_LOGO_PREFERENCE_OFF":
      return {
        ...state,
        ppt_logo: true,
      };
    case "USER_SET_PPT_LOGO_PREFERENCE_ON":
      return {
        ...state,
        ppt_logo: false,
      };
    case "USER_SET_PPT_COMPRESSION_PREFERENCE_HIGH": {
      return { ...state, ppt_high_res: false };
    }
    case "USER_SET_PPT_COMPRESSION_PREFERENCE_LOW": {
      return { ...state, ppt_high_res: true };
    }
    case "USER_SET_CARD_PREF_ENABLED_ACTION": {
      return { ...state, card_pref_share: false };
    }
    case "USER_SET_CARD_PREF_DISABLED_ACTION": {
      return { ...state, card_pref_share: true };
    }
    case "USER_SET_CARD_PREF_ENABLED_TIME": {
      return { ...state, card_pref_specific_time: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_TIME": {
      return { ...state, card_pref_specific_time: false };
    }
    case "USER_SET_CARD_PREF_DISABLED_TIMEAGO": {
      return { ...state, card_pref_time_ago: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_TIMEAGO": {
      return { ...state, card_pref_time_ago: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_STORE": {
      return { ...state, card_pref_store: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_STORE": {
      return { ...state, card_pref_store: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_CITY": {
      return { ...state, card_pref_city: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_CITY": {
      return { ...state, card_pref_city: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_STATE": {
      return { ...state, card_pref_state: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_STATE": {
      return { ...state, card_pref_state: true };
    }
    case "USER_SET_CARD_PREF_DISABLED_COUNTRY": {
      return { ...state, card_pref_country: false };
    }
    case "USER_SET_CARD_PREF_ENABLED_COUNTRY": {
      return { ...state, card_pref_country: true };
    }
    case "USER_SELECT_FONT_SIZE": {
      return { ...state, font_size_prefix: action.data };
    }
    case "USER_SELECT_LANGUAGE": {
      return { ...state, language_prefix: action.data };
    }
    case "USER_CHECK_PRICING_ACCESS_DATA":
      return {
        ...state,
        pricing_access: {
          last_updated: new Date(),
          show_facings: action.data.filter((x) => x.show_facings).length > 0,
          data_as_arr: action.data,
          data: action.data.reduce(function (result, item, index, array) {
            result[item.id] = item;
            return result;
          }, {}),
        },
      };
    case "USER_CHECK_PLANOGRAM_ACCESS_DATA":
      return {
        ...state,
        planogram_access: {
          last_updated: new Date(),
          data_as_arr: action.data,
          data: action.data.reduce(function (result, item, index, array) {
            result[item.id] = item;
            return result;
          }, {}),
        },
      };
    case "USER_SELECT_CONTRAST":
      return { ...state, contrast_prefix: action.data };
    case "USER_SET_PREFERENCE_PRICING_MASS":
      return { ...state, pricing_preference_mass: true };
    case "USER_SET_PREFERENCE_PRICING_CLUB":
      return { ...state, pricing_preference_mass: false };
    case "USERS_GET_INTERESTS_COMPANIES_DATA":
      return { ...state, company_interests: action.data };
    case "USER_LOGS_IN":
      return null;
    case "USER_TEAMMATES_DATA":
      return { ...state, team: action.data };
    case "USER_REFRESH_USING_SHELF_TOKEN_DATA":
    case "USER_AUTH_VIA_SOCIAL_SIGN_ON_DATA":
    case "ADMIN_IMPERSONATE_USER_DATA":
    case "USER_LOGS_IN_DATA":
      return action.data;
    case "HIDE_ORIENTATION":
      if (state) {
        return { ...state, show_onboarding: false };
      }
      return state;
    case "USER_SET_SORT_PREFERENCE":
      if (state) {
        return { ...state, sort_preference: action.data };
      }
      return state;
    case "USER_DISCOVERED_CLICK_TO_ZOOM":
      if (state) {
        return { ...state, feature_found_click_to_zoom: true };
      }
      return state;
    case "USER_COMPLETE_PROFILE_NAME_DATA":
      if (state && action.data.length > 0) {
        return {
          ...state,
          first_name: action.data[0].first_name,
          last_name: action.data[0].last_name,
          show_onboarding: true,
        };
      }
      return action.data;
    case "USER_SET_FEED_LAYOUT":
      if (state) {
        return { ...state, cards_in_layout: action.data };
      }
      return state;
    case "USER_SET_FLYER_LAYOUT":
      if (state) {
        return { ...state, cards_in_flyer_layout: action.data };
      }
      return state;
    case "CHECK_RECENT_UPLOADS_DATA":
      if (state) {
        return { ...state, photo_uploads: action.data };
      }
      return state;
    case "CHECK_RECENT_SHARE_LINKS_DATA":
      if (state) {
        return { ...state, share_links: action.data };
      }
      return state;
    case "FEATURE_FOUND_PHOTO_SHARING_DATA":
      if (state) {
        return { ...state, feature_found_photo_sharing: true };
      }
      return state;
    case "FEATURE_FOUND_FOLLOWING_FEED_DATA":
      if (state) {
        return { ...state, feature_found_following_feed: true };
      }
      return state;
    case "FEATURE_FOUND_TIPS_AND_TRICKS_DATA":
      if (state) {
        return { ...state, feature_found_tips_and_tricks: true };
      }
      return state;
    case "FEATURE_FOUND_KEYBOARD_SHORTCUTS_DATA":
      if (state) {
        return { ...state, feature_found_keyboard_shortcuts: true };
      }
      return state;
    case "FEATURE_FOUND_CREATE_A_COLLECTION_DATA":
      if (state) {
        return { ...state, feature_found_create_a_collection: true };
      }
      return state;
    case "FEATURE_FOUND_ORDER_PHOTOS_DATA":
      if (state) {
        return { ...state, feature_found_order_photos: true };
      }
      return state;
    case "FEATURE_FOUND_DARK_MODE_DATA":
      if (state) {
        return { ...state, feature_found_dark_mode: true };
      }
      return state;
    case "FEATURE_FOUND_HEATMAPS_DATA":
      if (state) {
        return { ...state, feature_found_heatmaps: true };
      }
      return state;
    case "FEATURE_FOUND_FLYERS_DATA":
      if (state) {
        return { ...state, feature_found_flyers: true };
      }
      return state;
    case "FEATURE_FOUND_SHELF_ALERTS_DATA":
      if (state) {
        return { ...state, feature_found_shelf_alerts: true };
      }
      return state;
    case "FEATURE_FOUND_TEXT_SEARCH_DATA":
      if (state) {
        return { ...state, feature_found_text_search: true };
      }
      return state;
    case "FEATURE_FOUND_MULTIPLE_FILTERS_DATA":
      if (state) {
        return { ...state, feature_found_multiple_filters: true };
      }
      return state;
    case "FEATURE_FOUND_MULTIPLE_SELECT_DATA":
      if (state) {
        return { ...state, feature_found_multiple_select: true };
      }
      return state;
    case "FEATURE_FOUND_POWERPOINT_EXPORT_DATA":
      if (state) {
        return { ...state, feature_found_powerpoint_export: true };
      }
      return state;
    case "TOGGLE_USER_POWERPOINT_PREFERENCE":
      return { ...state, powerpoint_preference: action.data };
    case "ALERT_CREATE_DATA":
      const currentAlerts = state.alerts || [];
      currentAlerts.unshift(action.data[0]);
      return { ...state, alerts: currentAlerts };
    case "UPDATE_PC_LIST_WITH_REQUESTED":
      if (state) {
        if (state.private_collections_requested) {
          const newPcList = state.private_collections_requested;
          newPcList.push(action.data);
          return { ...state, private_collections_requested: newPcList };
        } else {
          return {
            ...state,
            private_collections_requested: [action.data],
          };
        }
      }
      return state;
    case "LANDING_PAGE_ABOUT_DATA":
      if (state && state.plan_id === 1) {
        if (
          action.data &&
          action.data.length > 0 &&
          action.data[0].route === "private_collection" &&
          !action.data[0].downgraded &&
          action.data[0].collection_users
        ) {
          return { ...state, plan_id: 3 };
        }
      }
      return state;
    case "PRIVATE_COLLECTION_DATA":
      if (state && state.plan_id === 1) {
        const inActivePC = action.data.filter((x) => x.active && !x.downgraded);
        if (inActivePC.length > 0) {
          return { ...state, plan_id: 3 };
        }
      }
      return state;
    case "FOLLOW_THE_PAGE_LOCALLY":
      if (!state) {
        return { following: action.data };
      }
      // Remove from following suggestions if it exists
      let suggestedFollowing = cloneDeep(state.suggestions);
      if (suggestedFollowing) {
        suggestedFollowing = suggestedFollowing.filter(
          (item) => item.id !== action.data.id
        );
      }
      const newFollowingItems = state.following || [];
      // If exists, set following to true
      const itemExists = newFollowingItems.filter(
        (x) => x.id == action.data.id
      );
      if (itemExists.length > 0) {
        const itemIndex = newFollowingItems.indexOf(itemExists[0]);
        newFollowingItems[itemIndex].following = true;
        return Object.assign({}, state, {
          following: newFollowingItems,
          suggestions: suggestedFollowing,
        });
      }
      newFollowingItems.unshift(action.data);
      return {
        ...state,
        following: newFollowingItems,
        suggestions: suggestedFollowing,
      };
    case "UNFOLLOW_THE_PAGE_LOCALLY":
      let removeFollow = cloneDeep(state.following) || [];
      const itemToUnfollow = removeFollow.filter(
        (x) => x.id == action.data.id
      )[0];
      const removeIndex = removeFollow.indexOf(itemToUnfollow);
      if (removeFollow[removeIndex]) {
        removeFollow[removeIndex].following = false;
        return Object.assign({}, state, {
          following: removeFollow,
        });
      }
      return state;
    case "USER_FOLLOWING_SUGGESTIONS_DATA":
      const currentFollowingIds = state.following.map((x) => x.id);
      return {
        ...state,
        suggestions: action.data.filter(
          (item) => !currentFollowingIds.includes(item.id)
        ),
      };
    case "USER_FOLLOWING_PREFERENCES_DATA":
      if (state.following) {
        let newFollowingData = [];
        const newFollowingDataMap = new Map();
        for (const item of action.data) {
          if (!newFollowingDataMap.has(item.id)) {
            newFollowingDataMap.set(item.id, true); // set any value to Map
            newFollowingData.push(item);
          }
        }
        return Object.assign({}, state, {
          loadedFollowing: true,
          following: newFollowingData,
        });
      }
      return {
        ...state,
        loadedFollowing: true,
        following: action.data.sort(() => 0.5 - Math.random()),
      };
    case "UPLOAD_PHOTOS_ADD_TO_RECENT":
      if (state.photo_uploads) {
        return {
          ...state,
          uploaded_photos: true,
          photo_uploads: {
            ...state.photo_uploads,
            data: [action.data].concat(state.photo_uploads.data),
          },
        };
      } else {
        return {
          ...state,
          uploaded_photos: true,
          photo_uploads: { data: [action.data], page: 0 },
        };
      }
    case "USER_EMAIL_PREFERENCES_EMAIL_ME_MONTHLY_PHOTOS":
      return { ...state, email_me_monthly_photos: action.data };
    case "USER_EMAIL_PREFERENCES_EMAIL_ME_NEW_FEATURES":
      return { ...state, email_me_new_features: action.data };
    case "LOGOUT":
      return null;
    case "ACCEPT_TERMS_LOCALLY":
      return Object.assign({}, state, { accepted_latest_terms: true });
    case "USER_DATA":
      if (!action.data.error && action.data.length > 0) {
        return {
          ...state,
          ...action.data[0],
        };
      }
      return state;
    case "USER_DATA_REMOVE_ALERT":
      const userData2 = state.alerts;
      userData2.splice(action.data, 1);
      return Object.assign({}, state, { alerts: userData2 });
    case "USER_DATA_SET_SAVED_TRUE":
      if (state) {
        return {
          ...state,
          saved_photos: true,
        };
      }
    case "ALERT_UPDATE_TOGGLE_STATE":
      const userData3 = state.alerts;
      userData3[action.index].active = action.data;
      return Object.assign({}, state, { alerts: userData3 });
    case "ALERT_TILE_UPDATE_NAME":
      const userDataNameChange = state.alerts;
      userDataNameChange[action.data.index].name = action.data.name;
      return Object.assign({}, state, { alerts: userDataNameChange });
    case "ALERT_TILE_UPDATE_FILTERS":
      const userDataFiltersChange = state.alerts;
      userDataFiltersChange[action.data.index].settings = action.data.filters;
      return Object.assign({}, state, { alerts: userDataFiltersChange });
    case "ALERT_TILE_UPDATE_TIMING":
      const userData4 = state.alerts;
      userData4[action.data.index].timing = action.data.timing;
      return Object.assign({}, state, { alerts: userData4 });
    case "ADD_TO_UNSAVED":
      if (action.data.resetSaved) {
        return Object.assign({}, state, { saved_photos: false });
      }
      return state;
    case "USER_CHANGE_LOCAL_NAME":
      return Object.assign({}, state, {
        first_name: action.data.first_name,
        last_name: action.data.last_name,
      });
    case "USER_CHANGE_LOCAL_EMAIL":
      return Object.assign({}, state, { email: action.data.email });
    case "CHECK_ACCESS_DATA":
      return { ...state, private_collections: action.data.access };
    default:
      return state;
  }
};

export const dataPendingUpload = (state = null, action) => {
  switch (action.type) {
    case "UPLOAD_PHOTOS_DELETE_ONE":
      let strippedUpload = state.photos;
      strippedUpload = strippedUpload.filter(
        (item) => item.name !== action.data.name
      );
      return {
        ...state,
        photos: strippedUpload,
      };
    case "UPLOAD_PHOTOS_CREATE_UPLOAD_ID_DATA":
      return {
        ...state,
        uploadId: action.data,
      };
    case "UPLOAD_PHOTOS_SEND_DATA_TO_API_DATA":
      return {
        ...state,
        completed: state.completed.concat(action.data.name),
      };
    case "PENDING_UPLOAD_UPDATE_CONVERTED_FILE":
      const convertingCount = state.converting;
      const convertedPhotos = state.photos;
      convertedPhotos.splice(convertedPhotos.indexOf(action.data.old), 1);
      return Object.assign({}, state, {
        photos: convertedPhotos.concat(action.data.new),
        converting: convertingCount - 1,
      });
    case "PENDING_UPLOAD_DROP_PHOTOS":
      return {
        photos: action.data,
        completed: [],
      };
    case "PENDING_UPLOAD_DROP_FILES":
      return {
        files: action.data,
        completed: [],
      };
    case "CREATE_A_RETAILER_DATA":
      return Object.assign({}, state, {
        search: action.data.concat(state.search),
      });
    case "UPLOAD_PHOTOS_RETAILER_SEARCH_DATA":
      if (Array.isArray(action.data)) {
        return {
          ...state,
          search: action.data,
        };
      }
      return {
        ...state,
        search: [],
      };
    case "UPLOAD_PHOTOS_SET_RETAILER":
      return {
        ...state,
        retailer: action.data,
      };
    case "UPLOAD_PHOTOS_SET_DESTINATION":
      return {
        ...state,
        destination: action.data,
      };
    case "RESET_PENDING_UPLOAD":
      return null;
    default:
      return state;
  }
};

export const dataLoginPreview = (state = false, action) => {
  switch (action.type) {
    case "LOGIN_PREVIEW_DATA":
      return action.data;
    default:
      return state;
  }
};

// This should be removed
export const onboardingCompleted = (state = false, action) => {
  switch (action.type) {
    case "COMPLETED_ONBOARDING":
      return true;
    case "LOGOUT":
      return false;
    default:
      return state;
  }
};

export const hideOrientation = (state = false, action) => {
  switch (action.type) {
    case "HIDE_ORIENTATION":
      return true;
    case "LOGOUT":
      return false;
    default:
      return state;
  }
};

export const dataPhotosForReviewer = (state = {}, action) => {
  switch (action.type) {
    case "GET_PHOTOS_FOR_REVIEWER_DATA":
      return action.data;
    case "APPROVE_OR_REJECT_PHOTO_DATA":
      return {
        ...state,
        count: {
          count: state.count.count - 1,
        },
      };
    default:
      return state;
  }
};

export const orientationStages = (state = null, action) => {
  switch (action.type) {
    case "COMPLETED_ONBOARDING":
      return {};
    case "SHOW_VIEWER":
      if (state) {
        if (!state.completed_photo_view) {
          return { ...state, completed_photo_view: true };
        }
      }
      return state;
    case "FEED_SETTINGS_COLLECTIONS":
      if (state) {
        if (!state.explored_a_collection) {
          return { ...state, explored_a_collection: true };
        }
      }
      return state;
    case "USER_GET_MOBILE_APP":
      if (state) {
        if (!state.got_mobile_app) {
          return { ...state, got_mobile_app: true };
        }
      }
      return state;
    default:
      return state;
  }
};

export const dataGenerateReportPage = (state = null, action) => {
  switch (action.type) {
    case "GENERATE_A_REPORT":
      return action.data && action.data.photos.data;
    case "GENERATE_A_REPORT_FROM_SHARE_PAGE":
      return action.data;
    default:
      return state;
  }
};

export const dataCoverPhotoOptions = (state = null, action) => {
  switch (action.type) {
    case "COVER_PHOTO_OPTIONS_DATA":
    case "SEARCH_FOR_COVER_PHOTO_OPTIONS_DATA":
      return action.data;
    default:
      return state;
  }
};

export const dataPreviewPhotoOptions = (state = null, action) => {
  switch (action.type) {
    case "SEARCH_FOR_PREVIEW_PHOTO_OPTIONS_DATA":
      return action.data;
    default:
      return state;
  }
};

export const dataRecentSearches = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
      return null;
    case "RECENT_SEARCHES_DATA":
      if (action.data && action.data.error) {
        return state;
      } else {
        return { date: new Date(), data: action.data };
      }
    case "RECENT_SEARCHES_ADD":
      if (state?.data?.length) {
        if (state.data.length > 4) {
          const newData = [action.data, ...state.data.slice(0, state.data.length - 1)];
          return { date: new Date(), data: newData };
        }
        return { date: new Date(), data: [action.data].concat(state.data) };
      }
      return { date: new Date(), data: [action.data] };
    case "RECENT_SEARCHES_MOVE_TO_FRONT":
      return {
        date: new Date(),
        data: [
          action.data,
          ...state.data.filter((x) => x.id !== action.data.id),
        ],
      };
    default:
      return state;
  }
};

export const dataTrendingSearches = (state = null, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const dataSelectorSearches = (state = {}, action) => {
  switch (action.type) {
    case "RESET_SELECTOR_SEARCH_DATA":
      return {};
    case "SELECTOR_SEARCH_COMPANIES_DATA":
      return {
        ...state,
        companies: action.data,
      };
    case "SELECTOR_SEARCH_LOCATIONS_DATA":
      return {
        ...state,
        locations: action.data,
      };
    case "SELECTOR_SEARCH_LANGUAGES_DATA":
      return {
        ...state,
        languages: action.data,
      };
    default:
      return state;
  }
};

export const dataDirectory = (state = {}, action) => {
  switch (action.type) {
    case "INITIAL_LOAD_DATA":
      return {
        ...state,
        last_updated: action.data.last_updated,
        regions: { data: action.data.regions, page: 0, more: false },
        countries: { data: action.data.data.countries, page: 0, more: false },
        regions: { data: action.data.data.regions, page: 0, more: false },
        categories: { data: action.data.data.categories, page: 0, more: false },
        channels: { data: action.data.data.channels, page: 0, more: false },
        tags: { data: action.data.data.tags, page: 0, more: false },
      };
    case "DIRECTORY_COUNTRIES_DATA":
      return {
        ...state,
        countries: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_TAGS_DATA":
      if (state.tags && state.tags.data && action.data.page !== 0) {
        return {
          ...state,
          tags: {
            data: state.tags.data.concat(action.data.data),
            page: action.data.page,
            more: action.data.more,
          },
        };
      }
      return {
        ...state,
        tags: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_CATEGORIES_DATA":
      if (state.categories && state.categories.data && action.data.page !== 0) {
        return {
          ...state,
          categories: {
            data: state.categories.data.concat(action.data.data),
            page: action.data.page,
            more: action.data.more,
          },
        };
      }
      return {
        ...state,
        categories: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_CHANNELS_DATA":
      if (state.channels && state.channels.data && action.data.page !== 0) {
        return {
          ...state,
          channels: {
            data: state.channels.data.concat(action.data.data),
            page: action.data.page,
            more: action.data.more,
          },
        };
      }
      return {
        ...state,
        channels: {
          data: action.data.data,
          page: action.data.page,
          more: action.data.more,
        },
      };
    case "DIRECTORY_RETAILERS_DATA":
      return {
        ...state,
        retailers: action.data,
      };
    case "DIRECTORY_BRANDS_DATA":
      return {
        ...state,
        brands: action.data,
      };
    case "DIRECTORY_DATA_RESET":
      return {
        ...state,
        [action.data]: null,
      };
    default:
      return state;
  }
};

export const dataDirectorySettings = (
  state = {
    countries: {
      presetLanguages: [
        { id: 6019, value: "Arabic", label: "Arabic" },
        { id: 4601, value: "English", label: "English" },
        { id: 1683, value: "French", label: "French" },
        { id: 3875, value: "Italian", label: "Italian" },
        { id: 5808, value: "German", label: "German" },
        { id: 1171, value: "Spanish", label: "Spanish" },
        { id: 5788, value: "Russian", label: "Russian" },
        { id: 4524, value: "Portuguese", label: "Portuguese" },
        { id: 5048, value: "Hungarian", label: "Hungarian" },
        { id: 283, value: "Turkish", label: "Turkish" },
      ],
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
    malls: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
    channels: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
    categories: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
      type: {
        id: 1,
        value: "All Types",
        label: "All Types",
        noImg: true,
      },
    },
    brands: {
      sort: {
        id: "popularHigh",
        value: "popularHigh",
        label: "Most popular",
      },
    },
    retailers: {
      sort: {
        id: "popularHigh",
        value: "popularHigh",
        label: "Most popular",
      },
    },
    companies: {
      sort: {
        id: "popularHigh",
        value: "popularHigh",
        label: "Most popular",
      },
    },
    tags: {
      sort: {
        id: "alphabeticalAz",
        value: "alphabeticalAz",
        label: "Alphabetical (A - Z)",
      },
    },
  },
  action
) => {
  switch (action.type) {
    case "DIRECTORY_COUNTRIES_SORT":
      return {
        ...state,
        countries: {
          ...state.countries,
          sort: action.data,
        },
      };
    case "DIRECTORY_COUNTRIES_FILTER_REGIONS":
      return {
        ...state,
        countries: {
          ...state.countries,
          regions: action.data,
        },
      };
    case "DIRECTORY_COUNTRIES_FILTER_LANGUAGES":
      return {
        ...state,
        countries: {
          ...state.countries,
          languages: action.data,
        },
      };
    case "DIRECTORY_COUNTRIES_FILTER_GOVERNMENT_TYPES":
      return {
        ...state,
        countries: {
          ...state.countries,
          governmentTypes: action,
        },
      };
    case "DIRECTORY_FINANCIAL_SERVICES_SORT":
      return {
        ...state,
        financial_services: {
          ...state.financial_services,
          sort: action.data,
        },
      };
    case "DIRECTORY_MALLS_SORT":
      return {
        ...state,
        malls: {
          ...state.malls,
          sort: action.data,
        },
      };
    case "DIRECTORY_CHANNELS_SORT":
      return {
        ...state,
        channels: {
          ...state.channels,
          sort: action.data,
        },
      };
    case "DIRECTORY_CATEGORIES_SORT":
      return {
        ...state,
        categories: {
          ...state.categories,
          sort: action.data,
        },
      };
    case "DIRECTORY_RETAILERS_SORT":
      return {
        ...state,
        retailers: {
          ...state.retailers,
          sort: action.data,
        },
      };
    case "DIRECTORY_BRANDS_SORT":
      return {
        ...state,
        brands: {
          ...state.brands,
          sort: action.data,
        },
      };
    case "DIRECTORY_COMPANIES_FILTER_CATEGORIES":
      return {
        ...state,
        brands: {
          ...state.brands,
          categories: action.data,
        },
      };
    case "DIRECTORY_COMPANIES_FILTER_CHANNELS":
      return {
        ...state,
        retailers: {
          ...state.retailers,
          channels: action.data,
        },
      };
    case "DIRECTORY_TAGS_SORT":
      return {
        ...state,
        tags: {
          ...state.tags,
          sort: action.data,
        },
      };
    case "DIRECTORY_CATEGORIES_FILTER_TYPE":
      return {
        ...state,
        categories: {
          ...state.categories,
          type: action.data,
        },
      };
    default:
      return state;
  }
};

export const dataCollectionsPublicSettings = (
  state = {
    country: 5,
    type: [
      {
        id: 1,
        value: "Editor's picks",
        label: "Editor's picks",
      },
    ],
    sort: [
      {
        id: "popularHigh",
        value: "Most popular",
        label: "Most popular",
      },
    ],
  },
  action
) => {
  switch (action.type) {
    case "RETAIL_PIPELINES_SELECT_COUNTRY":
      return {
        ...state,
        country: action.data,
      };
    case "COLLECTIONS_PUBLIC_SORT":
      return {
        ...state,
        sort: [action.data],
      };
    case "COLLECTIONS_PUBLIC_FILTER_TYPE":
      return {
        ...state,
        type: [action.data],
      };
    default:
      return state;
  }
};

export const dataCollectionsPublic = (state = {}, action) => {
  switch (action.type) {
    case "COLLECTIONS_GET_FEATURED_DATA":
      return {
        ...state,
        featured: action.data,
      };
    case "COLLECTIONS_GET_COMMUNITY_DATA":
      return {
        ...state,
        community: action.data,
      };
    case "COLLECTIONS_GET_CUSTOM_COLLECTIONS_DATA":
      return {
        ...state,
        custom_collections: action.data,
      };
    case "COLLECTIONS_GET_RETAIL_PIPELINES_DATA":
      return {
        ...state,
        retail_pipelines: action.data,
      };
    case "LOGOUT":
    case "COLLECTIONS_DATA_RESET":
      return {};
    default:
      return state;
  }
};

export const dataCollectionsOwned = (state = null, action) => {
  switch (action.type) {
    case "RESET_DATA_COLLECTIONS_OWNED":
    case "LOGOUT":
      return null;
    case "COLLECTIONS_OWNED_DATA":
      return action.data;
    case "COLLECTION_CREATE_DATA":
      if (state) {
        const currentCollections = state.collections;
        const array = currentCollections.concat(action.data);
        return {
          ...state,
          collections: array,
        };
      } else {
        return {
          collections: [action.data],
          notYetLoaded: true,
        };
      }
    case "COLLECTIONS_UNLINK_PHOTO_LOCALLY":
      if (state) {
        const newCollectionsData = [];
        state.collections.forEach((item) => {
          if (item.id === action.data.collection_id) {
            newCollectionsData.push({
              ...item,
              photo_count: (item.photo_count -= 1),
            });
          } else {
            newCollectionsData.push(item);
          }
        });
        return Object.assign({}, state, { collections: newCollectionsData });
      }
      return state;
    case "COLLECTIONS_INCREMENT_PHOTO_COUNT":
      if (state) {
        const newCollectionsData = [];
        const collectionsUniqueItemsMap = new Map();
        action.data.forEach((value) => {
          state.collections.forEach((item) => {
            if (!collectionsUniqueItemsMap.has(item.id)) {
              collectionsUniqueItemsMap.set(item.id, true); // set any value to Map
              if (item.id === value) {
                newCollectionsData.push({
                  ...item,
                  photo_count: (item.photo_count += 1),
                });
              } else {
                newCollectionsData.push(item);
              }
            }
          });
        });
        return Object.assign({}, state, { collections: newCollectionsData });
      }
      return state;
    case "CHANGE_LANDING_PAGE_COVER":
      if (state) {
        const newCollectionsData = [];
        state.collections.forEach((item) => {
          if (item.id === action.data.landing_page_slug) {
            newCollectionsData.push({
              ...item,
              cover_url: action.data.cover,
            });
          } else {
            newCollectionsData.push(item);
          }
        });
        return Object.assign({}, state, { collections: newCollectionsData });
      }
      return state;
    case "CHANGE_LANDING_PAGE_NAME":
      if (state) {
        const newCollectionsData = [];
        state.collections.forEach((item) => {
          if (item.id === action.data.landing_page_slug) {
            newCollectionsData.push({
              ...item,
              name: action.data.name,
            });
          } else {
            newCollectionsData.push(item);
          }
        });
        return Object.assign({}, state, { collections: newCollectionsData });
      }
      return state;
    default:
      return state;
  }
};

export const dataCollectionsPrivate = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
      return null;
    case "PRIVATE_COLLECTION_DATA":
      if (action.data && !action.data.error) {
        return action.data;
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const dataLandingPageAbout = (state = null, action) => {
  switch (action.type) {
    case "PRIVATE_SPACE_TOGGLE_DOWNGRADE":
      return {
        ...state,
        downgraded: action.data,
      };
    case "CHANGE_LOCATION_STORE_NUMBER":
      return {
        ...state,
        store_num: action.data,
      };
    case "SHOW_FEED_AS_RETAILER":
      return {
        ...state,
        show_as_retailer: true,
      };
    case "SHOW_FEED_AS_BRAND":
      return {
        ...state,
        show_as_retailer: false,
      };
    case "PRICING_INCREMENT_PAGE":
      return {
        ...state,
        pricing: {
          ...state.pricing,
          page: state.pricing.page + 1,
        },
      };
    case "SHOW_PRICING_DATE_SELECTOR_START":
      return {
        ...state,
        showDatePicker: "start",
      };
    case "SHOW_PRICING_DATE_SELECTOR_END":
      return {
        ...state,
        showDatePicker: "end",
      };
    case "HIDE_PRICING_DATE_SELECTOR":
      return {
        ...state,
        showDatePicker: false,
      };
    case "FACINGS_GET_FACETS_DATA":
      return {
        ...state,
        facings_facets: { retailer: action.data },
      };
    case "FACINGS_SEARCH_FIRST_DATA":
      return {
        ...state,
        facings: { ...action.data, date: new Date().toString() },
      };
    case "USER_CHECK_COLLECTION_ACCESS_DATA":
      return {
        ...state,
        access_check: true,
        access: action.data.access,
      };
    case "PRICING_FILTER_APPLY_FEATURES":
      return {
        ...state,
        pricing_features: action.data,
      };
    case "PRICING_FILTER_APPLY_LOCATION":
      return {
        ...state,
        pricing_location_type: action.data.location_type,
        pricing_location_selections: action.data.location_selections,
      };
    case "PRICING_FILTER_APPLY_PRODUCT":
      return {
        ...state,
        pricing_product: action.data,
      };
    case "PRICING_FILTER_APPLY_DATE":
      return {
        ...state,
        pricing_dates: action.data,
      };
    case "PRICING_SHOW_DATE_PICKER_START":
      return {
        ...state,
        show_date_picker_start: true,
      };
    case "PRICING_SHOW_DATE_PICKER_END":
      return {
        ...state,
        show_date_picker_end: true,
      };
    case "PRICING_HIDE_DATE_PICKER":
      return {
        ...state,
        show_date_picker_start: false,
        show_date_picker_end: false,
      };
    case "PRICING_FILTER_APPLY_PRICE":
      return {
        ...state,
        pricing_filter: action.data,
      };
    case "PRICING_FILTER_APPLY_POSITIONS":
      return {
        ...state,
        pricing_positions: action.data,
      };
    case "COLLECTION_GET_MAX_AND_MIN_PRICE_DATA":
      return {
        ...state,
        pricing_boundaries: action.data,
      };
    case "PRODUCT_PRICING_DATA":
      return {
        ...state,
        pricing: action.data,
      };
    case "PRODUCT_PRICING_MORE_DATA":
      const uniquePricing = [];
      const mapPricing = new Map();

      const allPricing = ((state.pricing && state.pricing.data) || []).concat(
        action.data.data
      );
      for (const itemFacing of allPricing) {
        if (!mapPricing.has(itemFacing.id)) {
          mapPricing.set(itemFacing.id, true); // set any value to Map
          uniquePricing.push(itemFacing);
        }
      }
      return {
        ...state,
        pricing: {
          ...action.data,
          data: uniquePricing,
        },
      };
    case "MAP_RETAILER_FILTERS":
      return {
        ...state,
        map_filters: action.data,
      };
    case "FOLLOW_THE_PAGE_LOCALLY":
      return {
        ...state,
        following: true,
      };
    case "UNFOLLOW_THE_PAGE_LOCALLY":
      return {
        ...state,
        following: false,
      };
    case "CHANGE_COMPANY_PARENTS":
      return {
        ...state,
        parents: action.data,
      };
    case "CHANGE_COMPANY_CATEGORIES":
      return {
        ...state,
        categories: action.data,
      };
    case "CHANGE_COMPANY_CHANNELS":
      return {
        ...state,
        channels: action.data,
      };
    case "PRIVATE_COLLECTION_TOGGLE_SHOW_DASHBOARD":
      const currentShowDashboard = state.show_dashboard;
      return {
        ...state,
        show_dashboard: !currentShowDashboard,
      };
    case "PC_REMOVE_FILE_LOCALLY":
      const existingFiles = state.files || [];
      existingFiles.splice(action.data, 1);
      return Object.assign({}, state, { files: existingFiles });
    case "UPLOAD_FILES_SEND_DATA_TO_API_DATA":
      if (action.data && action.data.length > 0) {
        const currentFiles = state.files || [];
        currentFiles.unshift(action.data[0]);
        return { ...state, files: currentFiles };
      }
      return state;
    case "CREDIT_CARD_SUBMIT_DATA":
      return {
        ...state,
        ccof: true,
      };
    case "PRIVATE_COLLECTION_DASHBOARD_DATA":
      return {
        ...state,
        dashboard_collections: action.data.dashboard_collections || [],
        dashboard_companies: action.data.dashboard_companies || [],
        dashboard_tags: action.data.dashboard_tags || [],
        dashboard_categories: action.data.dashboard_categories || [],
        dashboard_channels: action.data.dashboard_channels || [],
        current_pc: {
          ...state.current_pc,
          dashboard: action.data,
        },
      };
    case "PRIVATE_COLLECTION_DASHBOARD_EDIT_COLLECTIONS":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            dashboard_collections: [],
          };
        }
        let dashboardUniqueCollections = [];
        const dashboardUniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!dashboardUniqueItemsMap.has(item.id)) {
            dashboardUniqueItemsMap.set(item.id, true); // set any value to Map
            dashboardUniqueCollections.push({
              id: item.id,
              value: item.value,
              background: item.background,
              route: item.route,
              source: item.source,
              img: item.img || "d6fd5d35-a370-4b36-8e14-de840bdf8890",
              type: "collections",
            });
          }
        }
        return {
          ...state,
          dashboard_collections: dashboardUniqueCollections,
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_EDIT_CHANNELS":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            dashboard_channels: [],
          };
        }
        let dashboardUniqueChannels = [];
        const dashboardUniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!dashboardUniqueItemsMap.has(item.id)) {
            dashboardUniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            dashboardUniqueChannels.push({
              id: item.id,
              value: item.value,
              background: item.background,
              route: item.route,
              img: img,
              type: "channels",
            });
          }
        }
        return {
          ...state,
          dashboard_channels: dashboardUniqueChannels,
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_EDIT_CATEGORIES":
      if (state && action.data) {
        if (action.data.length == 0) {
          return {
            ...state,
            dashboard_categories: [],
          };
        }
        let dashboardUniqueCategories = [];
        const dashboardUniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!dashboardUniqueItemsMap.has(item.id)) {
            dashboardUniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            dashboardUniqueCategories.push({
              id: item.id,
              value: item.value,
              background: item.background,
              route: item.route,
              img: img,
              type: "categories",
            });
          }
        }
        return {
          ...state,
          dashboard_categories: dashboardUniqueCategories,
        };
      } else {
        return {
          ...state,
          dashboard_categories: [],
        };
      }
    case "PRIVATE_COLLECTION_DASHBOARD_EDIT_TAGS":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            dashboard_tags: [],
          };
        }
        let dashboardUniqueTags = [];
        const dashboardUniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!dashboardUniqueItemsMap.has(item.id)) {
            dashboardUniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            dashboardUniqueTags.push({
              id: item.id,
              value: item.value,
              background: item.background,
              route: item.route,
              img: img,
              type: "tags",
            });
          }
        }
        return {
          ...state,
          dashboard_tags: dashboardUniqueTags,
        };
      }
      return state;
    case "PRIVATE_COLLECTION_DASHBOARD_EDIT_COMPANIES":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            dashboard_companies: [],
          };
        }
        let dashboardUniqueCompanies = [];
        const dashboardUniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!dashboardUniqueItemsMap.has(item.id)) {
            dashboardUniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            dashboardUniqueCompanies.push({
              id: item.id,
              value: item.value,
              background: item.background,
              route: item.route,
              img: img,
              type: "companies",
            });
          }
        }
        return {
          ...state,
          dashboard_companies: dashboardUniqueCompanies,
        };
      }
      return state;
    case "PRIVATE_COLLECTION_ADD_PUBLIC_CLOUD_CHANNELS":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            included_channels: [],
          };
        }
        let uniqueChannels = [];
        const uniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!uniqueItemsMap.has(item.id)) {
            uniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            uniqueChannels.push({
              id: item.id,
              value: item.value,
              img: img,
              type: "channels",
            });
          }
        }
        return {
          ...state,
          included_channels: uniqueChannels,
        };
      }
      return state;
    case "PRIVATE_COLLECTION_ADD_PUBLIC_CLOUD_CATEGORIES":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            included_categories: [],
          };
        }
        let uniqueCategories = [];
        const uniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!uniqueItemsMap.has(item.id)) {
            uniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            uniqueCategories.push({
              id: item.id,
              value: item.value,
              img: img,
              type: "categories",
            });
          }
        }
        return {
          ...state,
          included_categories: uniqueCategories,
        };
      }
      return state;
    case "PRIVATE_COLLECTION_ADD_PUBLIC_CLOUD_TAGS":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            included_tags: [],
          };
        }
        let uniqueTags = [];
        const uniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!uniqueItemsMap.has(item.id)) {
            uniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            uniqueTags.push({
              id: item.id,
              value: item.value,
              img: img,
              type: "tags",
            });
          }
        }
        return {
          ...state,
          included_tags: uniqueTags,
        };
      }
      return state;
    case "PRIVATE_COLLECTION_ADD_PUBLIC_CLOUD_COMPANIES":
      if (state) {
        if (action.data.length == 0) {
          return {
            ...state,
            included_companies: [],
          };
        }
        let uniqueCompanies = [];
        const uniqueItemsMap = new Map();
        for (const item of action.data) {
          if (!uniqueItemsMap.has(item.id)) {
            uniqueItemsMap.set(item.id, true); // set any value to Map
            let img = item.img;
            if (!img) {
              const fullSrc = item.label.props.children[0].props.src.split("/");
              img = fullSrc[fullSrc.length - 1];
            }
            uniqueCompanies.push({
              id: item.id,
              value: item.value,
              img: img,
              type: "companies",
            });
          }
        }
        return {
          ...state,
          included_companies: uniqueCompanies,
        };
      }
      return state;
    case "LANDING_PAGE_ABOUT_DATA":
      if (state && state.dashboard_tags) {
        return {
          ...state,
          ...action.data[0],
        };
      } else if (action.data.length > 0) {
        return action.data[0];
      }
      return "404";
    case "LOGOUT":
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return null;
    case "CHANGE_LANDING_PAGE_PROFILE_PIC":
      return {
        ...state,
        source: action.data.source,
        img: action.data.img,
      };
    case "CHANGE_LANDING_PAGE_NAME":
      return {
        ...state,
        name: action.data.name,
      };
    case "CHANGE_LANDING_PAGE_SUMMARY":
      return {
        ...state,
        summary: action.data,
      };
    case "CHANGE_LANDING_PAGE_COLORS":
      return {
        ...state,
        primary_color: action.data.primary_color,
        secondary_color: action.data.secondary_color,
      };
    case "CHANGE_LANDING_PAGE_COVER":
      return Object.assign({}, state, { cover_url: action.data.cover });
    default:
      return state;
  }
};

export const dataLandingPageAnalytics = (state = null, action) => {
  switch (action.type) {
    case "UPDATE_WHITELISTED_EMAILS":
      return {
        ...state,
        whitelisted_emails: action.data,
      };
    case "FACINGS_SHOW_SCATTER_PLOT":
      return {
        ...state,
        facings_show_scatter: true,
      };
    case "USER_SET_PREFERENCE_PRICING_MASS":
    case "FACINGS_SHOW_VISUALS":
      return {
        ...state,
        facings_show_scatter: false,
      };
    case "RESET_ANALYTICS_DATA":
      return {
        ...state,
        analytics: null,
      };
    case "COLLECTION_COVERAGE_MODEL_DATA":
      return {
        ...state,
        coverage_model: action.data,
      };
    case "ANALYTICS_DISCOVERABILITY_RESET_BRANDS":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          discoverability_brands: [],
        },
      };
    case "ANALYTICS_DISCOVERABILITY_ADD_BRAND":
      const currentDiscoverabilityBrands =
        state.analytics.discoverability_brands || [];
      return {
        ...state,
        analytics: {
          ...state.analytics,
          discoverability_brands: currentDiscoverabilityBrands.concat(
            action.data
          ),
        },
      };
    case "ANALYTICS_DISCOVERABILITY_REMOVE_BRAND":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          discoverability_brands: state.analytics.discoverability_brands.filter(
            (x) => x !== action.data
          ),
        },
      };
    case "ANALYTICS_POG_SIZE_OVER_TIME_RESET_RETAILERS":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pog_over_time_retailers: [],
        },
      };
    case "ANALYTICS_POG_SIZE_OVER_TIME_ADD_RETAILER":
      const currentPogSizeRetailers =
        state.analytics.pog_over_time_retailers || [];
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pog_over_time_retailers: currentPogSizeRetailers.concat(action.data),
        },
      };
    case "ANALYTICS_POG_SIZE_OVER_TIME_REMOVE_RETAILER":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pog_over_time_retailers:
            state.analytics.pog_over_time_retailers.filter(
              (x) => x !== action.data
            ),
        },
      };
    case "ANALYTICS_REPORT_GET_PLACE_DISTRIBUTION_TIME_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, distribution_time: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_OMNICHANNEL_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, omnichannel: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_FRONT_PAGE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, front_page: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_DISPLAY_CALENDAR_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, display_calendar: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BDI_BY_GEOGRAPHY_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, bdi_by_geography: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BDI_BY_URBANIZATION_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, bdi_by_urbanization: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BDI_BY_RETAILER_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, bdi_by_retailer: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_BRAND_ASSORTMENT_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, brand_assortment: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LISTINGS_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, listings: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_DISCOUNTING_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, discounting: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_PRICING_ASSORTMENT_GEOGRAPHY_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pricing_assortment_geography: action.data,
        },
      };
    case "ANALYTICS_REPORT_GET_PRICING_PRICING_ASSORTMENT_URBANIZATION_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pricing_assortment_urbanization: action.data,
        },
      };
    case "ANALYTICS_REPORT_GET_PRICING_PRICING_ASSORTMENT_RETAILER_DATA":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          pricing_assortment_retailer: action.data,
        },
      };
    case "ANALYTICS_REPORT_GET_PLACE_DISTRIBUTION_GAPS_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, distribution_gaps: action.data },
      };
    case "ANALYTICS_REPORT_GET_PROMOTION_DISPLAY_SHARE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, display_share: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_CATEGORY_ADJACENCIES_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, category_adjacencies: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_BRAND_ADJACENCIES_DATA":
      if (state.analytics && state.analytics.brand_adjacencies) {
        return {
          ...state,
          analytics: {
            ...state.analytics,
            brand_adjacencies: {
              ...state.analytics.brand_adjacencies,
              ...action.data,
            },
          },
        };
      } else {
        return {
          ...state,
          analytics: {
            ...state.analytics,
            brand_adjacencies: action.data,
          },
        };
      }
    case "ANALYTICS_REPORT_GET_PLACE_DISCOVERABILITY_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, discoverability: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_INFLATION_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, inflation: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_POG_OVER_TIME_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, pog_over_time: action.data },
      };
    case "ANALYTICS_REPORT_GET_PLACE_POG_SIZE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, pog_size: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LEADERS_BY_CHANNEL_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, leaders_by_channel: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LEADERS_BY_GEOGRAPHY_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, leaders_by_geography: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_LEADERS_BY_RETAILER_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, leaders_by_retailer: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRODUCT_SOCIAL_MEDIA_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, social_media: action.data },
      };
    case "ANALYTICS_REPORT_GET_PRICING_AVERAGE_DATA":
      return {
        ...state,
        analytics: { ...state.analytics, average: action.data },
      };
    case "SET_INCLUDED_CATEGORIES_FOR_ANALYTICS":
      return { ...state, included_categories: action.data };
    case "ANALYTICS_REPORT_INCLUDE":
      if (state.excluded_reports) {
        return {
          ...state,
          excluded_reports: state.excluded_reports.filter(
            (x) => x != action.data
          ),
        };
      }
      return state;
    case "ANALYTICS_REPORT_EXCLUDE":
      if (state.excluded_reports) {
        return {
          ...state,
          excluded_reports: [action.data].concat(state.excluded_reports),
        };
      }
      return { ...state, excluded_reports: [action.data] };
    case "REMOVE_PREAPPROVED_EMAIL_DOMAIN_LOCALLY":
      return {
        ...state,
        whitelisted_emails: state.whitelisted_emails.filter(
          (x) => x.preapproved_id != action.data
        ),
      };
    case "WHITELIST_EMAIL_DOMAIN_DATA":
      return {
        ...state,
        whitelisted_emails: [action.data]
          .concat(state.whitelisted_emails || [])
          .filter(
            (v, i, a) =>
              a.findIndex((t) => t.preapproved_id === v.preapproved_id) === i
          ),
      };
    case "LANDING_PAGE_ANALYTICS_DATA":
      if (action.data.length > 0) {
        return action.data[0];
      }
      return "404";
    case "LANDING_PAGE_USER_DATA":
      return Object.assign({}, state, { user_data: action.data });
    case "LANDING_PAGE_MORE_ANALYTICS_DATA":
      return Object.assign({}, state, { ...action.data[0], loadedMore: true });
    case "LANDING_PAGE_ANALYTICS_DATA_RESET":
      return null;
    case "TOGGLE_USER_PRIVATE_COLLECTION_ACCESS_LOCALLY":
      const currentUsers1 = state.user_data.map((x) => ({
        ...x,
        active: x.id == action.data.userId ? action.data.status : x.active,
      }));
      return Object.assign({}, state, { user_data: currentUsers1 });
    case "REMOVE_USER_FROM_COLLECTION":
      const currentUsers2 = state.user_data.filter((x) => x.id !== action.data);
      return Object.assign({}, state, { user_data: currentUsers2 });
    default:
      return state;
  }
};

export const dataLandingPageLocations = (state = null, action) => {
  switch (action.type) {
    case "LANDING_PAGE_LOCATIONS_DATA":
      return action.data;
    case "LANDING_PAGE_LOCATIONS_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataSearchQuery = (state = {}, action) => {
  switch (action.type) {
    case "SEARCH_QUERY_ALL_DATA":
      return { all: action.data };
    case "SEARCH_QUERY_CIRCULARS_DATA":
      return { ...state, circulars: action.data };
    case "SEARCH_QUERY_PHOTOS_DATA":
      return { ...state, photos: action.data };
    case "SEARCH_QUERY_BRANDS_DATA":
      return { ...state, brands: action.data };
    case "SEARCH_QUERY_RETAILERS_DATA":
      return { ...state, retailers: action.data };
    case "SEARCH_QUERY_CHANNELS_DATA":
      return { ...state, channels: action.data };
    case "SEARCH_QUERY_CATEGORIES_DATA":
      return { ...state, categories: action.data };
    case "SEARCH_QUERY_COLLECTIONS_DATA":
      return { ...state, collections: action.data };
    case "SEARCH_QUERY_STATES_DATA":
      return { ...state, states: action.data };
    case "SEARCH_QUERY_CITIES_DATA":
      return { ...state, cities: action.data };
    case "SEARCH_QUERY_COUNTRIES_DATA":
      return { ...state, countries: action.data };
    case "SEARCH_QUERY_TAGS_DATA":
      return { ...state, tags: action.data };
    case "SEARCH_QUERY_PRODUCTS_DATA":
      return { ...state, products: action.data };
    case "SEARCH_QUERY_DATA_RESET":
      return {};
    default:
      return state;
  }
};

export const dataTagsSettings = (
  state = {
    photo: null,
    tags: [],
    companies: [],
    categories: [],
  },
  action
) => {
  switch (action.type) {
    case "TAGS_DATA_SET":
      return {
        ...state,
        photo: action.data,
      };
    case "TAG_SETTINGS_TAGS":
      return {
        ...state,
        tags: action.data,
      };
    case "TAG_SETTINGS_COMPANIES":
      return {
        ...state,
        companies: action.data,
      };
    case "TAG_SETTINGS_CATEGORIES":
      return {
        ...state,
        categories: action.data,
      };
    case "TAG_SETTINGS_RESET":
      return {
        photo: null,
        tags: [],
        companies: [],
        categories: [],
      };
    default:
      return state;
  }
};

export const dataRemoveFromCollection = (state = null, action) => {
  switch (action.type) {
    case "REMOVE_FROM_COLLECTION_DATA_SET":
      return action.data;
    case "REMOVE_FROM_COLLECTION_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataAddToCollection = (state = null, action) => {
  switch (action.type) {
    case "ADD_TO_COLLECTION_DATA_SET":
      return action.data;
    case "ADD_TO_COLLECTION_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataAddToCollectionPhotoCheck = (state = null, action) => {
  switch (action.type) {
    case "COLLECTION_PHOTO_CHECK_DATA":
      return action.data && action.data.map((x) => x.url_slug);
    case "HIDE_VIEWER":
    case "@@router/LOCATION_CHANGE":
    case "LOGOUT":
    case "ADD_TO_COLLECTION_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataReport = (state = null, action) => {
  switch (action.type) {
    case "REPORT_DATA_SET":
      return action.data;
    case "REPORT_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataDelete = (state = null, action) => {
  switch (action.type) {
    case "DELETE_DATA_SET":
      return action.data;
    case "DELETE_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataShareLink = (state = null, action) => {
  switch (action.type) {
    case "SHARE_LINK_DATA_SET":
      return { data: action.data };
    case "SHORTEN_SHARE_LINK_DATA":
    case "SHORTEN_FLYER_SHARE_LINK_DATA":
    case "SHORTEN_PLANOGRAM_SHARE_LINK_DATA":
      return Object.assign({}, state, { shortUrl: action.data });
    case "SHARE_LINK_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataSharePage = (state = null, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      if (window.location.pathname.includes("/s/")) {
        return state;
      } else if (window.location.pathname.includes("/f/")) {
        return state;
      }
      return null;
    case "LOGOUT":
      return null;
    case "PHOTO_CHECK_SAVED_DATA":
      return { ...state, saved: action.data.saved };
    case "FLYERS_SIMILAR_ONES_DATA":
      return { ...state, similar_pages: action.data };
    case "FLYERS_GET_IN_STORE_PHOTOS_DATA":
      if (state) {
        return {
          ...state,
          store_photos: action.data,
        };
      }
      return state;
    case "VIEWER_DATA_SET":
      if (state) {
        return action.data;
      }
      return state;
    case "SAVE_UPDATE_LOCALLY":
    case "PHOTO_SAVE_UPDATE_LOCALLY":
      if (state) {
        return { ...state, saved: action.data.saved };
      }
      return state;
    case "SHARE_PAGE_DATA":
      if (action.data.error) {
        return state;
      } else {
        return {
          ...state,
          ...action.data,
          planogram: action.data.planogram_imgs,
          categories:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "categories"),
          channels:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "channels"),
          collections:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "collections"),
          companies:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "companies"),
          tags:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "tags"),
        };
      }
    case "FLYER_GET_TAGS_FOR_VIEWER_DATA":
      return {
        ...state,
        ...action.data,
        loaded: true,
      };
    case "PHOTO_GET_TAGS_FOR_VIEWER_DATA":
      if (state) {
        return {
          ...state,
          loaded: true,
          private_collection: action.data.private_collection,
          partner_img: action.data.partner_img,
          partner_url: action.data.partner_url,
          demography: action.data.demography,
          demography_id: action.data.demography_id,
          demography_img: action.data.demography_img,
          planogram: action.data.planogram_imgs,
          saved: action.data.saved,
          username: action.data.username,
          categories: action.data.tags.filter((x) => x.type == "categories"),
          channels: action.data.tags.filter((x) => x.type == "channels"),
          collections: action.data.tags.filter((x) => x.type == "collections"),
          companies: action.data.tags.filter((x) => x.type == "companies"),
          tags: action.data.tags.filter((x) => x.type == "tags"),
          products: action.data.products,
          weather: action.data.weather,
        };
      }
      return state;
    default:
      return state;
  }
};

export const dataGalleryPage = (state = {}, action) => {
  switch (action.type) {
    case "PLANOGRAM_PAGE_DATA":
      return action.data && action.data.length > 0
        ? action.data[0]
        : action.data;
    case "GALLERY_PAGE_DATA":
      return { ...state, data: action.data };
    case "GALLERY_PAGE_DECODE_TOKEN_DATA":
      return { ...state, decoded: action.data };
    case "PHOTO_SAVE_UPDATE_LOCALLY":
      if (state && state.data && state.data.imgs) {
        let feedItems = [];
        for (const item of state.data.imgs) {
          if (item.id === action.data.photo_id) {
            feedItems.push({
              ...item,
              saved: action.data.saved,
            });
          } else {
            feedItems.push(item);
          }
        }
        return {
          ...state,
          data: { ...state.data, imgs: feedItems },
        };
      }
      return state;
    default:
      return state;
  }
};

const defaultDataViewerSettings = {
  activeComment: null,
  pogPosition: null,
  showSideBar: true,
  showOsa: false,
  showFullScreen: false,
  showTimeTravel: false,
  showTextAnnotations: false,
  sideBarTab: "details",
  textQuery: "",
};

export const dataViewerSettings = (
  state = defaultDataViewerSettings,
  action
) => {
  switch (action.type) {
    case "HIDE_HEATMAP":
      return {
        ...defaultDataViewerSettings,
        showSideBar: true,
      };
    case "SHOW_HEATMAP":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
      };
    case "VIEWER_SHOW_TIME_TRAVEL":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "time_travel",
        showTimeTravel: true,
        showSideBar: false,
      };
    case "PHOTO_VIEW_DATA":
      return {
        ...state,
        viewerOpenAsync: true,
      };
    case "SHOW_OSA":
      return {
        ...defaultDataViewerSettings,
        showOsa: true,
        showSideBar: state.showSideBar,
      };
    case "HIDE_OSA":
      return {
        ...state,
        showOsa: false,
      };
    case "VIEWER_POG_SET_POSITION":
      return {
        ...state,
        pogPosition: action.data,
      };
    case "SHOW_TEXT_ANNOTATIONS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "text",
        showSideBar: false,
        textQuery: "",
        showTextAnnotations: true,
      };
    case "VIEWER_HIDE_FULLSCREEN":
      return {
        ...state,
        showFullScreen: false,
      };
    case "VIEWER_SHOW_FULLSCREEN":
      return {
        ...state,
        showFullScreen: true,
      };
    case "VIEWER_SHOW_FLYER":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
        sideBarTab: "flyer",
      };
    case "VIEWER_SHOW_MAP":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
        sideBarTab: "map",
      };
    case "VIEWER_SHOW_DETAILS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "details",
      };
    case "VIEWER_SHOW_STUDIO":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "studio",
        showSideBar: false,
      };
    case "VIEWER_SHOW_COMMENTS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "comments",
        showSideBar: true,
      };
    case "VIEWER_SHOW_COLLECTIONS":
      return {
        ...defaultDataViewerSettings,
        sideBarTab: "collections",
      };
    case "VIEWER_CLOSE_SIDEBAR":
      return {
        ...defaultDataViewerSettings,
        showSideBar: false,
      };
    case "CONFIRM_NEW_REPLY":
    case "OPEN_A_COMMENT":
    case "CONFIRM_NEW_COMMENT":
    case "CANCEL_ACTIVE_COMMENT":
      return { ...state, activeComment: null };
    case "ADD_NEW_COMMENT":
      return { ...state, activeComment: { ...action.data, open: true } };
    case "HIDE_VIEWER":
    case "@@router/LOCATION_CHANGE":
    case "LOGOUT":
    case "SHOW_DRAW":
    case "VIEWER_OPEN_SIDEBAR":
    case "VIEWER_HIDE_TIME_TRAVEL":
      return defaultDataViewerSettings;
    case "VIEWER_TEXT_QUERY":
      return { ...state, viewerTextQuery: action.data };
    case "SHOW_TAGGING":
    case "RESET_VIEWER_TEXT_QUERY":
      return { ...state, viewerTextQuery: null };
    case "SET_TEXT_QUERY_FROM_VIEWER":
      return { ...state, textQuery: action.data };
    case "HIDE_TEXT_ANNOTATIONS":
      return { ...state, showTextAnnotations: false, showSideBar: true };
    case "SET_TEXT_QUERY_IN_VIEWER":
      return { ...state, textQuery: action.data, showTextAnnotations: true };
    default:
      return state;
  }
};

export const dataViewer = (state = null, action) => {
  switch (action.type) {
    case "TIME_TRAVEL_SPECIFIC_PHOTO_DATA":
      return {
        ...state,
        time_travel: action.data,
      };
    case "OSA_ANNOTATIONS_DATA":
      return {
        ...state,
        osa: action.data,
      };
    case "PHOTO_RESPONSE_GROUP_CONTENT_DATA":
      return {
        ...state,
        response_group: action.data && action.data[0],
      };
    case "REVERSE_POG_DIRECTION":
      return {
        ...state,
        planogram: action.data,
      };
    case "QR_CODE_GET_CAMPAIGN_DATA":
      return {
        ...state,
        campaign_photos: action.data.map((x) => x.url_s3),
        campaign_photo_ids: action.data.map((x) => x.id),
      };
    case "PHOTO_GET_QR_CODES_DATA":
      return {
        ...state,
        qr_codes: action.data,
      };
    case "VIEWER_RELATED_FACINGS_DATA":
      return {
        ...state,
        facings: action.data,
      };
    case "FLYER_FROM_PHOTO_DATA":
      return { ...state, flyer_pages: action.data };
    case "PHOTO_CHECK_SAVED_DATA":
      return { ...state, saved: action.data.saved };
    case "FLYERS_SIMILAR_ONES_DATA":
      return { ...state, similar_pages: action.data };
    case "RELATED_PLANOGRAM_DATA":
      return { ...state, planogram: action.data };
    case "CONFIRM_NEW_REPLY":
      return {
        ...state,
        comments: state.comments.map((x, i) =>
          i === action.data.index
            ? { ...x, replies: x.replies.concat(action.data) }
            : x
        ),
      };
    case "CONFIRM_NEW_COMMENT":
      if (state && state.comments) {
        return {
          ...state,
          comments: state.comments.concat({ ...action.data, open: true }),
        };
      } else {
        return { ...state, comments: [{ ...action.data, open: true }] };
      }
    case "DELETE_A_COMMENT":
      return {
        ...state,
        comments: state.comments.filter(
          (x) => x.x !== action.data.x && x.y !== action.data.y
        ),
      };
    case "OPEN_A_COMMENT":
      return {
        ...state,
        comments: state.comments.map((x) => ({
          ...x,
          open: x.x === action.data.x && x.y === action.data.y,
          justCreated: false,
        })),
      };
    case "ADD_NEW_COMMENT":
    case "HIDE_COMMENT_MODE":
    case "CANCEL_ACTIVE_COMMENT":
      if (state && state.comments) {
        return {
          ...state,
          comments: state.comments.map((x) => ({ ...x, open: false })),
        };
      }
      return state;
    case "SAVE_UPDATE_LOCALLY":
    case "PHOTO_SAVE_UPDATE_LOCALLY":
      return {
        ...state,
        saved: action.data.saved,
      };
    case "FLYERS_GET_IN_STORE_PHOTOS_DATA":
      return {
        ...state,
        store_photos: action.data,
      };
    case "RELATED_PHOTOS_SAME_DATA":
      return {
        ...state,
        related_photos_same: action.data,
      };
    case "RELATED_PHOTOS_OTHER_DATA":
      return {
        ...state,
        related_photos_other: action.data,
      };
    case "FLYER_GET_TAGS_FOR_VIEWER_DATA":
      return {
        ...state,
        ...action.data,
        loaded: true,
      };
    case "PHOTO_GET_TAGS_FOR_VIEWER_DATA":
      return {
        ...state,
        loaded: true,
        has_flyer: action.data.has_flyer,
        private_collection: action.data.private_collection,
        partner_img: action.data.partner_img,
        partner_url: action.data.partner_url,
        demography: action.data.demography,
        demography_id: action.data.demography_id,
        demography_img: action.data.demography_img,
        saved: action.data.saved,
        username: action.data.username,
        products: action.data.products,
        weather: action.data.weather,
        planogram: action.data.planogram_imgs,
        categories: action.data.tags.filter((x) => x.type == "categories"),
        channels: action.data.tags.filter((x) => x.type == "channels"),
        collections: action.data.tags.filter((x) => x.type == "collections"),
        companies: action.data.tags.filter((x) => x.type == "companies"),
        tags: action.data.tags.filter((x) => x.type == "tags"),
      };
    case "TEXT_ANNOTATIONS_DATA":
      if (state) {
        return {
          ...state,
          text: action.data,
        };
      }
      return { text: action.data };
    case "SHARE_PAGE_DATA":
      if (action.data.error) {
        return state;
      } else {
        return {
          ...state,
          ...action.data,
          planogram: action.data.planogram_imgs,
          categories:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "categories"),
          channels:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "channels"),
          collections:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "collections"),
          companies:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "companies"),
          tags:
            action.data.tags &&
            action.data.tags.filter((x) => x.type == "tags"),
        };
      }
    case "VIEWER_DATA":
      if (state) {
        return {
          ...state,
          ...action.data[0],
        };
      }
      return action.data[0];
    case "VIEWER_DATA_SET":
      if (action.data) {
        return action.data;
      }
      return state;
    case "HIDE_VIEWER":
      return null;
    case "APPEND_TAGS_LOCALLY":
      if (state) {
        const { tagType, url_slug, value: name, primary_color, img } = action.data;
        if (!state[tagType]?.find(tag => tag.url_slug === url_slug)) {
          return {
            ...state,
            [tagType]: [...state[tagType], { url_slug, name, primary_color, img }]
          };
        }
      }
      return state;
    case "REMOVE_TAG_LOCALLY_TAGS":
      if (state) {
        const updatedItems = [];
        for (const item of state.tags) {
          if (item.url_slug !== action.data.url_slug) {
            updatedItems.push({
              url_slug: item.url_slug,
              name: item.name,
              img: item.img,
            });
          }
        }
        return {
          ...state,
          tags: updatedItems,
        };
      }
      return state;
    case "REMOVE_TAG_LOCALLY_CATEGORIES":
      if (state) {
        const updatedItems = [];
        for (const item of state.categories) {
          if (item.url_slug !== action.data.url_slug) {
            updatedItems.push({
              url_slug: item.url_slug,
              name: item.name,
              img: item.img,
            });
          }
        }
        return {
          ...state,
          categories: updatedItems,
        };
      }
      return state;
    case "REMOVE_TAG_LOCALLY_COMPANIES":
      if (state) {
        const updatedItems = [];
        for (const item of state.companies) {
          if (item.url_slug !== action.data.url_slug) {
            updatedItems.push({
              url_slug: item.url_slug,
              primary_color: item.primary_color,
              name: item.name,
              img: item.img,
            });
          }
        }
        return {
          ...state,
          companies: updatedItems,
        };
      }
      return state;
    case "TAGS_REMOVE_COLLECTIONS_LOCALLY":
      return {
        ...state,
        collections: state.collections.filter((x) => x.url_slug != action.data),
      };
    case "TAGS_ADD_COLLECTIONS_LOCALLY":
      if (state) {
        let uniqueItems = [];
        const currentCollections = state.collections || [];
        if (action.data.length > 0) {
          const uniqueItemsMap = new Map();
          for (const item of currentCollections.concat(action.data)) {
            if (!uniqueItemsMap.has(item.url_slug)) {
              uniqueItemsMap.set(item.url_slug, true); // set any value to Map
              uniqueItems.push({
                url_slug: item.url_slug,
                name: item.name,
                img: item.img,
              });
            }
          }
        }
        return {
          ...state,
          collections: uniqueItems,
        };
      }
      return state;
    default:
      return state;
  }
};

export const dataSearchByText = (state = null, action) => {
  switch (action.type) {
    case "SEARCH_PHOTOS_BY_TEXT_DATA":
      return action.data;
    case "PHOTO_SEARCH_QUERY_DATA_RESET":
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const dataFeed = (state = null, action) => {
  switch (action.type) {
    case "APPLY_PREFETCHED_PHOTOS":
      return { ...action.data, last_updated: new Date() };
    case "FEED_PHOTOS_DATA":
      if (state) {
        if (action.data.data.length > 0) {
          const array = clone(state.data).concat(action.data.data);
          const result = [];
          const map = new Map();
          for (const item of array) {
            if (!map.has(item.id)) {
              map.set(item.id, true); // set any value to Map
              result.push(item);
            }
          }
          return {
            ...state,
            data: result,
            meta: action.data.meta,
            page: action.data.page,
            where: action.data.where,
          };
        } else if (action.data.page > 0) {
          return { ...state, meta: action.data.meta, page: action.data.page };
        }
        const uniquePhotos = [];
        const mapPhotos = new Map();
        for (const itemPhotos of action.data.data) {
          if (!mapPhotos.has(itemPhotos.id)) {
            mapPhotos.set(itemPhotos.id, true); // set any value to Map
            uniquePhotos.push(itemPhotos);
          }
        }
        return { ...action.data, data: uniquePhotos };
      } else if (
        action.data &&
        action.data.location == window.location.pathname
      ) {
        const uniquePhotos2 = [];
        const mapPhotos2 = new Map();
        for (const itemPhotos2 of action.data.data) {
          if (!mapPhotos2.has(itemPhotos2.id)) {
            mapPhotos2.set(itemPhotos2.id, true); // set any value to Map
            uniquePhotos2.push(itemPhotos2);
          }
        }
        return { ...action.data, data: uniquePhotos2 };
      }
      return state;
    case "COLLECTIONS_UNLINK_PHOTO_LOCALLY":
      const dataWithUnlinkedPhoto = state.data;
      dataWithUnlinkedPhoto.splice(state.data.indexOf(action.data.data), 1);
      return Object.assign({}, state, { data: dataWithUnlinkedPhoto });
    case "PHOTO_SAVE_UPDATE_LOCALLY":
      if (state) {
        let feedItems = [];
        for (const item of state.data) {
          if (item.id === action.data.photo_id) {
            feedItems.push({
              ...item,
              saved: action.data.saved,
            });
          } else {
            feedItems.push(item);
          }
        }
        return {
          ...state,
          data: feedItems,
        };
      }
      return state;
    case "FEED_PHOTOS_DATA_RESET":
      return null;
    default:
      return state;
  }
};

export const lastApiCall = (state = [], action) => {
  switch (action.type) {
    case "HIDE_OFFLINE":
      return [];
    case "REMOVE_FROM_LAST_API_CALL":
      return state.filter((call) => call !== action.data);
    case "LAST_API_CALL":
      const newState = clone(state).concat(action.payload).reverse();
      if (newState.length == 1) {
        return newState;
      }
      const uniqueResult = [];
      const map = new Map();
      for (const item of newState) {
        if (!map.has(item.component)) {
          map.set(item.component, true);
          uniqueResult.push(item);
        }
      }
      return uniqueResult;
    default:
      return state;
  }
};

export const lastVisitUser = (state = "settings", action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return "settings";
    case "SET_SHARED_PHOTOS":
      return "shared_photos";
    case "SET_SHELF_ALERTS":
      return "shelf_alerts";
    case "SET_MY_PHOTOS":
      return "my_photos";
    case "SET_UPLOAD_PHOTOS":
      return "upload_photos";
    default:
      return state;
  }
};

export const lastVisitDirectory = (state = "channels", action) => {
  switch (action.type) {
    case "SET_LAST_VISIT_DIRECTORY_CATEGORIES":
      return "categories";
    case "SET_LAST_VISIT_DIRECTORY_CHANNELS":
      return "channels";
    case "SET_LAST_VISIT_DIRECTORY_COUNTRIES":
      return "countries";
    case "SET_LAST_VISIT_DIRECTORY_TAGS":
      return "tags";
    case "SET_LAST_VISIT_DIRECTORY_BRANDS":
      return "brands";
    case "SET_LAST_VISIT_DIRECTORY_RETAILERS":
      return "retailers";
    case "SET_LAST_VISIT_DIRECTORY_REGIONS":
      return "regions";
    default:
      return state;
  }
};

export const uploadLocation = (state = false, action) => {
  switch (action.type) {
    case "SET_LOCATION_ID":
      return { id: action.id, locationName: action.locationName };
    case "RESET_LOCATION_ID":
      return false;
    default:
      return state;
  }
};

export const reportedList = (state = [], action) => {
  switch (action.type) {
    case "LOGOUT":
      return [];
    case "ADD_TO_REPORTED":
      if (state && state.length > 0) {
        return action.data.concat(state);
      }
      return action.data;
    default:
      return state;
  }
};

export const unsavedList = (state = [], action) => {
  switch (action.type) {
    case "ADD_TO_UNSAVED":
      return state.concat(action.data.id);
    case "RESET_UNSAVED":
      return [];
    default:
      return state;
  }
};

export const showUpload = (state = false, action) => {
  switch (action.type) {
    case "OPEN_UPLOAD":
      return true;
    case "CLOSE_UPLOAD":
      return false;
    default:
      return state;
  }
};

export const showSearch = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SEARCH":
      return true;
    case "@@router/LOCATION_CHANGE":
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
    case "SHOW_SIDE_MENU":
    case "HIDE_SEARCH":
      return false;
    default:
      return state;
  }
};

export const showHeatmap = (state = false, action) => {
  switch (action.type) {
    case "SHOW_HEATMAP":
      return true;
    case "SHOW_OSA":
    case "VIEWER_SHOW_FULLSCREEN":
    case "VIEWER_SHOW_TIME_TRAVEL":
    case "SHOW_TEXT_ANNOTATIONS":
    case "VIEWER_SHOW_MAP":
    case "HIDE_VIEWER":
    case "HIDE_HEATMAP":
      return false;
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showDraw = (state = false, action) => {
  switch (action.type) {
    case "SHOW_DRAW":
      return true;
    case "VIEWER_DATA_SET":
    case "HIDE_VIEWER":
    case "HIDE_DRAW":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showViewer = (state = false, action) => {
  switch (action.type) {
    case "CHANGE_VIEWER_TYPE":
      return { ...state, type: action.data };
    case "VIEWER_DATA_SET":
      return { ...state, show_date: new Date() };
    case "SHOW_VIEWER":
      return action.data
        ? typeof variable === "string"
          ? { type: action.data, show_date: new Date() }
          : { ...action.data, show_date: new Date() }
        : true;
    case "HIDE_VIEWER":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showViewerPog = (state = false, action) => {
  switch (action.type) {
    case "SHOW_PLANOGRAM_EXPANDED_VIEWER":
      return {
        ...state,
        carousel: action.data
          ? { ...action.data, show_date: new Date() }
          : true,
      };
    case "SHOW_PLANOGRAM_HISTORICAL_VIEWER":
      return {
        ...state,
        historical: action.data
          ? { ...action.data, show_date: new Date() }
          : true,
      };
    case "GET_HISTORICAL_PLANOGRAMS_DATA":
      if (state) {
        return {
          ...state,
          historical: { ...state.historical, historical: action.data },
        };
      }
      return state;
    case "HIDE_VIEWER_PLANOGRAM_CAROUSEL":
      return { ...state, carousel: false };
    case "HIDE_VIEWER_PLANOGRAM_HISTORICAL":
      return { ...state, historical: false };
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFullscreenModal = (state = false, action) => {
  switch (action.type) {
    case "MAP_FILTER_LOCATIONS_DATA":
      return { ...state, pois: action.data };
    case "MAP_FILTER_SET_OUTSIDE":
      return {
        ...state,
        is: false,
      };
    case "MAP_FILTER_SET_INSIDE":
      return {
        ...state,
        is: true,
      };
    case "SHOW_FULLSCREEN_MODAL":
      return { ...action.data } || true;
    case "REMOVE_FILTER_CONDITION":
    case "HIDE_FULLSCREEN_MODAL":
    case "SAVE_MAP_FILTER":
    case "@@router/LOCATION_CHANGE":
    case "LOGOUT":
      return false;
    default:
      return state;
  }
};
export const showModal = (state = false, action) => {
  switch (action.type) {
    case "SHOW_MODAL":
      return { data: action.data, extraData: action.extraData };
    case "GENERATE_WORD_CLOUD_DATA":
      return { ...state, extraData: action.data };
    case "LOGOUT":
    case "RESET_SELECT_MULTIPLE_PHOTOS":
    case "RESET_SELECT_MULTIPLE_FLYERS":
    case "PRICING_FILTER_APPLY_FEATURES":
    case "PRICING_FILTER_APPLY_DATE":
    case "PRICING_FILTER_APPLY_LOCATION":
    case "PRICING_FILTER_APPLY_PRICE":
    case "PRICING_FILTER_APPLY_POSITIONS":
    case "RESET_SELECT_MULTIPLE_PLANOGRAMS":
    case "FEED_FILTERS_CHANGE_SORT":
    case "FLYERS_FILTERS_CHANGE_SORT":
    case "STAR_CHECK_ON":
    case "PLANOGRAM_FILTERS_CHANGE_SORT":
    case "HIDE_VIEWER":
    case "SHOW_VIEWER":
    case "APPLY_PLANOGRAM_FILTERS":
    case "WHITELIST_EMAIL_DOMAIN_DATA":
    case "SWITCH_PRIVATE_SPACE":
    case "HIDE_MODAL":
    case "FLYER_FILTERS_APPLY_NEW_CHANGES":
    case "@@router/LOCATION_CHANGE":
      return false;
    case "USER_LOGS_IN_DATA":
      if (state && action.data.shelf_token) {
        return false;
      }
      return state;
    default:
      return state;
  }
};

export const reportedSubmitted = (state = false, action) => {
  switch (action.type) {
    case "SUBMIT_REPORT":
      return true;
    case "RESET_REPORT":
      return false;
    default:
      return state;
  }
};

export const checkEnterpriseTld = (state = null, action) => {
  switch (action.type) {
    case "LOGOUT":
    case "@@router/LOCATION_CHANGE":
    case "RESET_CHECK_ENTERPRISE_TLD":
      return null;
    case "CHECK_SSO_TLD_DATA":
      if (Array.isArray(action.data)) {
        return action.data[0];
      }
      return null;
    case "CHECK_SSO_TLD_AND_GET_EMAIL_CODE_DATA":
      if (Array.isArray(action.data)) {
        return { checked: true, validated: action.data[0] };
      } else if (action.data.free_email_tld) {
        return { checked: true, validated: false, free_email_tld: true };
      } else if (action.data.deactivated) {
        return { checked: true, validated: false, deactivated: true };
      } else if (action.data.redirect) {
        return { redirect: action.data.redirect };
      }
      return {
        ...action.data,
        checked: true,
        validated: false,
        deactivated: false,
      };
    default:
      return state;
  }
};

// To Be Deprecated
export const feedSort = (state = "random", action) => {
  switch (action.type) {
    case "RANDOM":
      return "random";
    case "POPULAR":
      return "popular";
    case "OLDEST":
      return "oldest";
    case "NEWEST":
      return "newest";
    default:
      return state;
  }
};

// To Be Deprecated
export const feedView = (state = "viewAsTiles", action) => {
  switch (action.type) {
    case "CARDS":
      return "viewAsCards";
    case "PHOTOS":
      return "viewAsPhotos";
    case "SLIDESHOW":
      return "viewAsDashboard";
    case "TILES":
      return "viewAsTiles";
    default:
      return state;
  }
};

// To Be Deprecated
export const advertiseSignUpStatus = (state = {}, action) => {
  switch (action.type) {
    case "ACTIVE":
      return true;
    default:
      return false;
  }
};

export const showKeyboardShortcuts = (state = false, action) => {
  switch (action.type) {
    case "KEYBOARD_SHORTCUTS":
      return true;
    case "HIDE_SHORTCUTS":
      return false;
    default:
      return state;
  }
};

// To be deprecated
export const showFeedSortMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_SORT_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_SHOW_USER":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_DIRECTORY":
    case "SHOW_FEED_TYPE_MENU":
    case "SHOW_MODAL":
    case "SHOW_FEED_COMPANY_TYPE_MENU":
    case "MAIN_MENU_HIDE":
    case "@@router/LOCATION_CHANGE":
    case "SHOW_FEED_MORE_MENU":
      return false;
    default:
      return state;
  }
};

export const showFeedCompanyTypeMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_COMPANY_TYPE_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_MORE_MENU":
    case "SHOW_MODAL":
    case "MAIN_MENU_HIDE":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFlyerFilterMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FLYER_FILTER_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "MAIN_MENU_SHOW_USER":
    case "HIDE_FLYER_FILTER_MENU":
    case "SHOW_FLYER_DATE_PICKER":
    case "SHOW_FEED_NAV_MENU":
    case "HIDE_FLYER_DROPDOWN_MENUS":
    case "MAIN_MENU_HIDE":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_MODAL":
    case "SHOW_FEED_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFeedTypeMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_TYPE_MENU":
      return true;
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_HIDE":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_MODAL":
    case "SHOW_FEED_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showFeedNavMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_NAV_MENU":
      return true;
    case "SHOW_FEED_COMPANY_TYPE_MENU":
    case "SHOW_FEED_TYPE_MENU":
    case "SHOW_PLANOGRAM_MORE_MENU":
    case "MAIN_MENU_HIDE":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_USER":
    case "USER_SET_PREFERENCE_PRICING_MASS":
    case "USER_SET_PREFERENCE_PRICING_CLUB":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_FEED_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
    case "SHOW_MODAL":
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
    case "LOCATIONS_MAP_ZOOM_IN":
    case "LOCATIONS_MAP_ZOOM_OUT":
    case "HIDE_FEED_DROPDOWN_MENUS":
      return false;
    default:
      return state;
  }
};

// THIS CAN BE DEPRECATED
export const showFeedShareMenu = (state = false, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "HIDE_FEED_SHIFT_MULTIPLE_SELECT":
    case "MAIN_MENU_HIDE":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_MORE_MENU":
    case "SHOW_FEED_NAV_MENU":
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_FEED_TYPE_MENU":
    case "SHOW_MODAL":
    case "SHOW_PLANOGRAM_MORE_MENU":
      return false;
    default:
      return state;
  }
};

export const showFeedMoreMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_FEED_MORE_MENU":
      return true;
    case "SHOW_FEED_COMPANY_TYPE_MENU":
    case "MAIN_MENU_HIDE":
    case "SHOW_FEED_SORT_MENU":
    case "SHOW_FEED_TYPE_MENU":
    case "SHOW_FEED_NAV_MENU":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_MODAL":
    case "@@router/LOCATION_CHANGE":
    case "HIDE_FEED_DROPDOWN_MENUS":
      return false;
    default:
      return state;
  }
};

export const showRVIDateMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_RVI_DATE_MENU":
      return true;
    case "MAIN_MENU_HIDE":
    case "HIDE_RVI_DATE_MENU":
    case "SHOW_RVI_BRAND_MENU":
    case "SHOW_MODAL":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showRVIBrandMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_RVI_BRAND_MENU":
      return true;
    case "MAIN_MENU_HIDE":
    case "HIDE_RVI_BRAND_MENU":
    case "SHOW_RVI_DATE_MENU":
    case "SHOW_MODAL":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const showPlanogramMoreMenu = (state = false, action) => {
  switch (action.type) {
    case "SHOW_PLANOGRAM_MORE_MENU":
      return true;
    case "SHOW_MODAL":
    case "SHOW_SEARCH":
    case "HIDE_FEED_DROPDOWN_MENUS":
    case "SHOW_FEED_NAV_MENU":
    case "SHOW_SIDE_MENU":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_USER":
    case "SHOW_FEED_SHIFT_MULTIPLE_SELECT":
    case "HIDE_PLANOGRAM_MORE_MENU":
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

export const newProductUpdates = (state = null, action) => {
  switch (action.type) {
    case "CHECK_FOR_NEW_PRODUCT_UPDATES_DATA":
      return {
        lastChecked: new Date(),
        data: action.data,
      };
    case "RESET_CHECK_FOR_NEW_PRODUCT_UPDATES":
      return null;
    default:
      return state;
  }
};

export const adminData = (state = {
  pcSearch: null,
  pendingPc: null,
  questionCodes: null,
  questionCodeMetadata: null
}, action) => {
  switch (action.type) {
    case "ANALYTICS_AGING_REPORTS_DATA":
      return { ...state, agingReports: action.data };
    case "SEARCH_FOR_A_PRIVATE_SPACE_DATA":
      return { ...state, pcSearch: action.data };
    case "PRIVATE_SPACE_SEARCH_QUERY_DATA_RESET":
      return { ...state, pcSearch: null };
    case "CREATE_NEW_PC_EDIT_COMPANY":
      return { ...state, pendingPC: { company: action.data } };
    case "PRIVATE_SPACE_CREATE_DATA":
      return { ...state, pendingPC: { created: true } };
    case "QUESTION_CODES_DATA":
      return { ...state, questionCodes: action.data };
    case "SHOW_MODAL":
    case "HIDE_MODAL":
      if (!["NEW_QUESTION_CODE", "CANCEL_NEW_QUESTION_CODE"].includes(action.data)) {
        return state;
      }
      return {
        ...state,
        questionCodes: {
          ...state.questionCodes,
          pendingQuestionCode: EMPTY_QUESTION_CODE
        }
      };
    case "UPDATE_QUESTION_CODE":
      return {
        ...state,
        questionCodes: {
          ...state.questionCodes,
          by_id: {
            ...state.questionCodes.by_id,
            [action.data.id]: action.data
          }
        }
      };
    case "CREATE_QUESTION_CODE":
      return {
        ...state,
        questionCodes: {
          ...state.questionCodes,
          pendingQuestionCode: action.data
        }
      };
    case "CREATE_QUESTION_CODE_DATA":
      return {
        ...state,
        questionCodes: {
          all_ids: [action.data.id, ...state.questionCodes.all_ids],
          by_id: {
            ...state.questionCodes.by_id,
            [action.data.id]: { ...state.questionCodes.pendingQuestionCode, id: action.data.id }
          },
          pendingQuestionCode: EMPTY_QUESTION_CODE
        }
      }
    case "DELETE_QUESTION_CODE_DATA":
      const { [action.data.id]: _, ...remainingQuestions } = state.questionCodes.by_id;
      return {
        ...state,
        questionCodes: {
          all_ids: state.questionCodes.all_ids.filter(id => id !== action.data.id),
          by_id: remainingQuestions
        }
      };
    case "QUESTION_CODE_METADATA_DATA":
      return { ...state, questionCodeMetadata: action.data };
    default:
      return state;
  }
};

export const dataTagger = (state = null, action) => {
  switch (action.type) {
    case "GET_PHOTO_FOR_TAGGER_DATA":
      return action.data;
    case "ADD_LOGO_FROM_TAGGER_DATA":
    case "REJECT_TAG_FROM_TAGGER_DATA":
    case "ADD_TAG_FROM_TAGGER_DATA":
    case "ADD_CATEGORIES_TO_COMPANY_FROM_TAGGER_DATA":
    case "OPT_OUT_OF_TAGGER_DATA":
    case "SKIP_TAGGER_REQUEST_DATA":
    case "ADD_CHANNELS_TO_COMPANY_FROM_TAGGER_DATA":
      return null;
    default:
      return state;
  }
};

export const dataFeedUntagged = (state = null, action) => {
  switch (action.type) {
    case "GET_PHOTOS_FOR_UNTAGGED":
      return null;
    case "GET_PHOTOS_FOR_UNTAGGED_DATA":
      return action.data;
    default:
      return state;
  }
};

const defaultShowMenuDropdown = {
  user: false,
  pcDashboard: false,
  help: false,
  notifications: false,
};

export const showMenuDropdown = (state = defaultShowMenuDropdown, action) => {
  switch (action.type) {
    case "MAIN_MENU_SHOW_USER":
      return {
        ...defaultShowMenuDropdown,
        user: true,
      };
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
      return {
        ...defaultShowMenuDropdown,
        notifications: true,
      };
    case "MAIN_MENU_SHOW_HELP":
      return {
        ...defaultShowMenuDropdown,
        help: true,
      };
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
      return {
        ...defaultShowMenuDropdown,
        pcDashboard: true,
      };
    case "SHOW_MODAL":
      return {
        ...defaultShowMenuDropdown,
        pcDashboard: state.pcDashboard,
      };
    case "HIDE_SIDE_MENU":
    case "SHOW_SIDE_MENU":
    case "MAIN_MENU_HIDE":
    case "CLEAR_ENTERPRISE_LOGO_IN_MENU":
    case "CAMPAIGNS_SEARCH_FOCUS":
    case "PUBLICATIONS_SEARCH_FOCUS":
    case "LOGOUT":
    case "SHOW_SEARCH":
    case "@@router/LOCATION_CHANGE":
      return defaultShowMenuDropdown;
    default:
      return state;
  }
};

export const showMainMenuDropdown = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SIDE_MENU":
    case "MAIN_MENU_HIDE":
    case "CAMPAIGNS_SEARCH_FOCUS":
    case "PUBLICATIONS_SEARCH_FOCUS":
    case "LOGOUT":
    case "SHOW_MODAL":
    case "@@router/LOCATION_CHANGE":
      return false;
    case "MAIN_MENU_SHOW_USER":
      return "user";
    case "MAIN_MENU_SHOW_FEED":
      return "feed";
    default:
      return state;
  }
};

export const dataLandingPageRVI = (state = false, action) => {
  switch (action.type) {
    case "OSA_ANNOTATIONS_PLANOGRAM_DATA":
      return {
        ...state,
        planogram_osa: {
          ...state.planogram_osa,
          [action.data.key]: action.data.data,
        },
      };
    case "PLANOGRAM_FILTERS_AVAILABE_VISIT_COUNT_DATA":
      return { ...state, available_visit_counts: action.data };
    case "PLANOGRAM_FILTERS_AVAILABE_SIZES_DATA":
      return { ...state, available_sizes: action.data };
    case "PLANOGRAM_FILTERS_AVAILABE_BRANDS_DATA":
      return { ...state, available_brands: action.data };
    case "PLANOGRAM_FILTERS_AVAILABE_STATES_DATA":
      if (state) {
        return { ...state, available_states: action.data };
      }
      return { available_states: action.data };
    case "ANALYTICS_GET_MISSING_REPORTS_DATA":
      if (state) {
        return { ...state, ...action.data };
      }
      return action.data;
    case "ANALYTICS_UNMOUNT":
      return { ...state, planogram_checks: null };
    case "ANALYTICS_PLANOGRAM_CHECK_DATA":
      if (state) {
        return {
          ...state,
          planogram_checks: { data: action.data, last_updated: new Date() },
        };
      }
      return {
        planogram_checks: { data: action.data, last_updated: new Date() },
      };
    case "RVI_ANALYTICS_DATA":
      if (state) {
        return { ...state, ...action.data };
      }
      return action.data;
    case "LOGOUT":
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return false;
    case "PRESENCE_SELECT_WEEK_DATA":
      return { ...state, presence: action.data };
    case "LOOK_OF_SUCCESS_DATA":
      if (state) {
        return { ...state, look_of_success: action.data };
      }
      return state;
    default:
      return state;
  }
};

export const dataEmbed = (state = null, action) => {
  switch (action.type) {
    case "FEED_PHOTOS_EMBED_DATA":
      return action.data;
    default:
      return state;
  }
};

export const powerPointExportSettings = (state = null, action) => {
  switch (action.type) {
    case "ANALYTICS_SELECT_ALL":
      let defaultPpt = {};
      action.data.forEach((item) => {
        defaultPpt[item.camelCase] = item;
      });
      return defaultPpt;
    case "ANALYTICS_REPORT_ADD":
      if (state) {
        return { ...state, [action.data.camelCase]: { ...action.data } };
      }
      return { [action.data.camelCase]: { ...action.data } };
    case "ANALYTICS_REPORT_REMOVE":
      return Object.assign({}, state, { [action.data.camelCase]: null });
    case "HIDE_MODAL":
      return null;
    default:
      return state;
  }
};

export const dataLandingPageRVISettings = (
  state = defaultRviSettings,
  action
) => {
  switch (action.type) {
    case "LOGOUT":
      return defaultRviSettings;
    case "HIDE_MODAL":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.existing,
          },
        },
      };
    case "PLANOGRAM_FILTER_CHANGE_VISIT_COUNT":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            visit_count: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTER_CHANGE_SIZES":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            sizes: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTERS_CHANGE_SORT":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          sort: action.data,
          filtersLastUpdated: new Date(),
        },
      };
    case "PLANOGRAM_FILTERS_SET_DATE_END":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            endDate: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTERS_SET_DATE_START":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            startDate: action.data,
          },
        },
      };
    case "PLANOGRAM_FILTERS_HIDE_DATE_PICKER":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          showDatePicker: false,
        },
      };
    case "PLANOGRAM_FILTERS_SHOW_DATE_PICKER":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          showDatePicker: action.data,
        },
      };
    case "APPLY_PLANOGRAM_FILTERS": {
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          existing: action.data,
          new: action.data,
          filtersLastUpdated: new Date(),
        },
      };
    }
    case "OPEN_POG_SUBFILTER": {
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          expanded_filter: action.data,
        },
      };
    }
    case "CLOSE_ALL_POG_SUBFILTERS": {
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          expanded_filter: null,
        },
      };
    }
    case "ANALYTICS_PLANOGRAM_COLLECTION":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            collections: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_RETAILER":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            retailers: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_BRAND":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            brands: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_STATE":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            states: action.data,
          },
        },
      };
    case "ANALYTICS_PLANOGRAM_OSA":
      return {
        ...state,
        planogram_checks: {
          ...state.planogram_checks,
          new: {
            ...state.planogram_checks.new,
            osa: action.data,
          },
        },
      };
    case "ANALYTICS_UNMOUNT":
      return { ...defaultRviSettings, selected: state.selected };
    case "ANALYTICS_SET_CHART":
      return { ...state, selected: action.data };
    case "ANALYTICS_SET_NATIONAL_VISIBILITY_BRAND_INTERACTION":
      return {
        ...state,
        national_visibility: {
          ...state.national_visibility,
          toggle: "brand_interaction",
        },
      };
    case "ANALYTICS_SET_NATIONAL_VISIBILITY_OVERALL":
      return {
        ...state,
        national_visibility: {
          ...state.national_visibility,
          toggle: "overall",
        },
      };
    case "ANALYTICS_SET_RETAILER_PIE":
      return {
        ...state,
        leaders_by_retailer: {
          ...state.leaders_by_retailer,
          toggle: "pie",
        },
      };
    case "ANALYTICS_SET_RETAILER_TABLE":
      return {
        ...state,
        leaders_by_retailer: {
          ...state.leaders_by_retailer,
          toggle: "table",
        },
      };
    case "ANALYTICS_SET_GEOGRAPHY_URBANIZATION":
      return {
        ...state,
        leaders_by_geography: {
          ...state.leaders_by_geography,
          toggle: "urbanization",
        },
      };
    case "ANALYTICS_SET_GEOGRAPHY_STATE":
      return {
        ...state,
        leaders_by_geography: {
          ...state.leaders_by_geography,
          toggle: "state",
        },
      };
    case "ANALYTICS_SET_PRESENCE_WEEK":
      return {
        ...state,
        presence: { ...state.presence, week: action.data.id },
      };
    case "ANALYTICS_ANOMALY_DETECTION_WEEK":
      return {
        ...state,
        anomaly_detection: { ...state.anomaly_detection, week: action.data.id },
      };
    case "ANALYTICS_OVERALL_FILTER":
      return {
        ...state,
        national_visibility: {
          ...state.national_visibility,
          filters: action.data,
        },
      };
    case "ANALYTICS_BRAND_SUPPORT_BY_RETAILER_INCLUDE_RETAILERS":
      const currentExcludedRetailers =
        state.brand_support_by_retailer.excludedRetailers;
      if (currentExcludedRetailers.includes(action.data.id)) {
        return {
          ...state,
          brand_support_by_retailer: {
            ...state.brand_support_by_retailer,
            excludedRetailers: currentExcludedRetailers.filter(
              (x) => x !== action.data.id
            ),
          },
        };
      } else {
        currentExcludedRetailers.push(action.data.id);
      }
      return {
        ...state,
        brand_support_by_retailer: {
          ...state.brand_support_by_retailer,
          excludedRetailers: currentExcludedRetailers,
        },
      };
    case "ANALYTICS_DISCOVERABILITY_RETAILER":
      return {
        ...state,
        discoverability: {
          ...state.discoverability,
          retailer: action.data,
        },
      };
    case "ANALYTICS_BRAND_INTERACTION_COLUMNS_TO_SHOW":
      return {
        ...state,
        brand_interaction: {
          ...state.brand_interaction,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_LEADER_BY_URBANIZATION_COLUMNS_TO_SHOW":
      return {
        ...state,
        leaders_by_urbanization: {
          ...state.leaders_by_urbanization,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_LEADER_BY_GEOGRAPHY_COLUMNS_TO_SHOW":
      return {
        ...state,
        leaders_by_geography: {
          ...state.leaders_by_geography,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_LEADER_BY_RETAILER_COLUMNS_TO_SHOW":
      return {
        ...state,
        leaders_by_retailer: {
          ...state.leaders_by_retailer,
          columnsToShow: action.data,
        },
      };
    case "ANALYTICS_GET_MISSING_REPORTS_DATA":
      if (
        action.data &&
        action.data.leaders_by_demography &&
        action.data.leaders_by_demography.length > 0
      ) {
        const preselectedBrand = action.data.leaders_by_demography[0];
        return {
          ...state,
          look_of_success: {
            ...state.look_of_success,
            selectedOption: {
              id: preselectedBrand.company_id,
              img: preselectedBrand.img,
              name: preselectedBrand.name,
              primary_color: preselectedBrand.primary_color,
            },
          },
        };
      } else {
        return state;
      }
    case "ANALYTICS_LOOK_OF_SUCCESS_BRAND":
      return {
        ...state,
        look_of_success: {
          ...state.look_of_success,
          selectedOption: action.data,
        },
      };
    case "ANALYTICS_SET_PRESENCE_MAIN_SECTION":
      return {
        ...state,
        presence: { ...state.presence, toggle: "main_section" },
      };
    case "ANALYTICS_SET_PRESENCE_DISPLAY":
      return { ...state, presence: { ...state.presence, toggle: "display" } };
    case "ANALYTICS_SET_CATEGORY_AVAILABILITY_DISPLAY":
      return {
        ...state,
        category_availability: {
          ...state.category_availability,
          toggle: "display",
        },
      };
    case "ANALYTICS_SET_CATEGORY_AVAILABILITY_MAIN_SECTION":
      return {
        ...state,
        category_availability: {
          ...state.category_availability,
          toggle: "main_section",
        },
      };
    case "ANALYTICS_SET_LOOK_OF_SUCCESS_MAIN_SECTION":
      return {
        ...state,
        look_of_success: {
          ...state.look_of_success,
          toggle: "main_section",
        },
      };
    case "ANALYTICS_SET_LOOK_OF_SUCCESS_DISPLAY":
      return {
        ...state,
        look_of_success: {
          ...state.look_of_success,
          toggle: "display",
        },
      };
    default:
      return state;
  }
};

export const cacheBreakers = (
  state = {
    feed: null,
  },
  action
) => {
  switch (action.type) {
    case "ADD_FEED_CACHE_BREAKING_ACTION":
      return {
        ...state,
        feed: {
          time: Date.now(),
          description: action.data,
        },
      };
    default:
      return state;
  }
};

export const feedCurrentlyFetching = (state = false, action) => {
  switch (action.type) {
    case "API_CALL_TAKE_LATEST":
      return true;
    case "APPLY_PREFETCHED_FLYERS":
    case "APPLY_PREFETCHED_PHOTOS":
    case "FEED_PHOTOS_DATA":
      return false;
    default:
      return state;
  }
};

export const dateOfLastLogin = (state = null, action) => {
  switch (action.type) {
    case "USER_AUTH_VIA_SOCIAL_SIGN_ON_DATA":
    case "USER_LOGS_IN_DATA":
      return Date.now();
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

const defaultSuggestions = {
  channels: null,
  categories: null,
  brands: null,
  retailers: null,
  tags: null,
  countries: null,
  collections: null,
  states: null,
};

export const dataFeedSuggestionsLandingPage = (
  state = defaultSuggestions,
  action
) => {
  switch (action.type) {
    case "LOGOUT":
      return defaultSuggestions;
    case "ADD_LOCAL_SUGGESTION_TO_COLLECTIONS":
      const existingCollections = [action.data].concat(
        clone(state.collections || [])
      );
      const uniqueExistingCollections = [];
      existingCollections.map((x) =>
        uniqueExistingCollections.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingCollections.push(x)
      );
      return {
        ...state,
        collections: uniqueExistingCollections.slice(0, 10),
      };
    case "ADD_LOCAL_SUGGESTION_TO_CHANNELS":
      const existingChannels = [action.data].concat(
        clone(state.channels || [])
      );
      const uniqueExistingChannels = [];
      existingChannels.map((x) =>
        uniqueExistingChannels.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingChannels.push(x)
      );
      return {
        ...state,
        channels: uniqueExistingChannels.slice(0, 10),
      };
    case "ADD_LOCAL_SUGGESTION_TO_STATES":
      const existingStates = [action.data].concat(clone(state.states || []));
      const uniqueExistingStates = [];
      existingStates.map((x) =>
        uniqueExistingStates.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingStates.push(x)
      );
      return {
        ...state,
        states: uniqueExistingStates.slice(0, 10),
      };
    case "ADD_LOCAL_SUGGESTION_TO_COUNTRIES":
      const existingCountries = [action.data].concat(
        clone(state.countries || [])
      );
      const uniqueExistingCountries = [];
      existingCountries.map((x) =>
        uniqueExistingCountries.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingCountries.push(x)
      );
      return {
        ...state,
        countries: uniqueExistingCountries.slice(0, 10),
      };
    case "ADD_LOCAL_SUGGESTION_TO_RETAILERS":
      const existingRetailers = [action.data].concat(
        clone(state.retailers || [])
      );
      const uniqueExistingRetailers = [];
      existingRetailers.map((x) =>
        uniqueExistingRetailers.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingRetailers.push(x)
      );
      return {
        ...state,
        retailers: uniqueExistingRetailers.slice(0, 100),
      };
    case "ADD_LOCAL_SUGGESTION_TO_BRANDS":
      const existingBrands = [action.data].concat(clone(state.brands || []));
      const uniqueExistingBrands = [];
      existingBrands.map((x) =>
        uniqueExistingBrands.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingBrands.push(x)
      );
      return {
        ...state,
        brands: uniqueExistingBrands.slice(0, 100),
      };
    case "ADD_LOCAL_SUGGESTION_TO_CATEGORIES":
      const existingCategories = [action.data].concat(
        clone(state.categories || [])
      );
      const uniqueExistingCategories = [];
      existingCategories.map((x) =>
        uniqueExistingCategories.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingCategories.push(x)
      );
      return {
        ...state,
        categories: uniqueExistingCategories,
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_COLLECTIONS_DATA":
      return {
        ...state,
        collections: action.data.error ? [] : action.data,
        last_updated_collections: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_CHANNELS_DATA":
      return {
        ...state,
        channels: action.data.error ? [] : action.data,
        last_updated_channels: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_COUNTRIES_DATA":
      return {
        ...state,
        countries: action.data.error ? [] : action.data,
        last_updated_countries: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_CITIES_DATA":
      return {
        ...state,
        cities: action.data.error ? [] : action.data,
        last_updated_cities: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_STATES_DATA":
      return {
        ...state,
        states: action.data.error ? [] : action.data,
        last_updated_states: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_CATEGORIES_DATA":
      return {
        ...state,
        categories: action.data.error ? [] : action.data,
        last_updated_categories: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_BRANDS_DATA":
      return {
        ...state,
        brands: action.data.error ? [] : action.data,
        last_updated_brands: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_RETAILERS_DATA":
      return {
        ...state,
        retailers: action.data.error ? [] : action.data,
        last_updated_retailers: new Date(),
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_LP_TAGS_DATA":
      return {
        ...state,
        tags: action.data.error ? [] : action.data,
        last_updated_tags: new Date(),
      };
    case "LANDING_PAGE_ABOUT_DATA_RESET":
      return defaultSuggestions;
    default:
      return state;
  }
};

export const dataFeedSuggestionsMain = (state = defaultSuggestions, action) => {
  switch (action.type) {
    case "SWITCH_PRIVATE_SPACE":
    case "LOGOUT":
      return defaultSuggestions;
    case "INITIAL_LOAD_DATA":
      return {
        ...defaultSuggestions,
        countries: action.data.data.countries.filter((x) => x.suggested),
      };
    case "ADD_LOCAL_SUGGESTION_TO_COLLECTIONS":
      const existingCollections = clone(state.collections || []);
      existingCollections.unshift(action.data);
      const uniqueExistingCollections = [];
      existingCollections.map((x) =>
        uniqueExistingCollections.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingCollections.push(x)
      );
      return {
        ...state,
        collections: uniqueExistingCollections,
      };
    case "ADD_LOCAL_SUGGESTION_TO_CHANNELS":
      const existingChannels = clone(state.channels || []);
      existingChannels.unshift(action.data);
      const uniqueExistingChannels = [];
      existingChannels.map((x) =>
        uniqueExistingChannels.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingChannels.push(x)
      );
      return {
        ...state,
        channels: uniqueExistingChannels,
      };
    case "ADD_LOCAL_SUGGESTION_TO_STATES":
      const existingStates = clone(state.states || []);
      existingStates.unshift(action.data);
      const uniqueExistingStates = [];
      existingStates.map((x) =>
        uniqueExistingStates.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingStates.push(x)
      );
      return {
        ...state,
        states: uniqueExistingStates.slice(0, 100),
      };
    case "ADD_LOCAL_SUGGESTION_TO_COUNTRIES":
      const existingCountries = clone(state.countries || []);
      existingCountries.unshift(action.data);
      const uniqueExistingCountries = [];
      existingCountries.map((x) =>
        uniqueExistingCountries.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingCountries.push(x)
      );
      return {
        ...state,
        countries: uniqueExistingCountries,
      };
    case "ADD_LOCAL_SUGGESTION_TO_RETAILERS":
      const existingRetailers = clone(state.retailers || []);
      existingRetailers.push(action.data);
      const uniqueExistingRetailers = [];
      existingRetailers.map((x) =>
        uniqueExistingRetailers.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingRetailers.push(x)
      );
      return {
        ...state,
        retailers: uniqueExistingRetailers.slice(0, 100),
      };
    case "ADD_LOCAL_SUGGESTION_TO_BRANDS":
      const existingBrands = clone(state.brands || []);
      existingBrands.unshift(action.data);
      const uniqueExistingBrands = [];
      existingBrands.map((x) =>
        uniqueExistingBrands.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingBrands.push(x)
      );
      return {
        ...state,
        brands: uniqueExistingBrands.slice(0, 100),
      };
    case "ADD_LOCAL_SUGGESTION_TO_CATEGORIES":
      const existingCategories = clone(state.categories || []);
      existingCategories.unshift(action.data);
      const uniqueExistingCategories = [];
      existingCategories.map((x) =>
        uniqueExistingCategories.filter((a) => a.id == x.id).length > 0
          ? null
          : uniqueExistingCategories.push(x)
      );
      return {
        ...state,
        categories: uniqueExistingCategories,
      };
    case "FEED_FILTERS_GET_SUGGESTIONS_MAIN_DATA":
      return {
        ...state,
        last_updated: new Date(),
        collections: action.data.collections,
        retailers: action.data.retailers,
        brands: action.data.brands,
        cities: action.data.cities,
        states: action.data.states,
      };
    default:
      return state;
  }
};

export const fixPreference = (state = "audits", action) => {
  switch (action.type) {
    case "FIX_OPTIONS_SET_AUDIT":
      return "audits";
    case "FIX_OPTIONS_SET_BROKER":
      return "broker";
    default:
      return state;
  }
};

export const dataFlyers = (state = null, action) => {
  switch (action.type) {
    case "APPLY_PREFETCHED_FLYERS":
      return { ...action.data, last_updated: new Date() };
    case "FEED_FLYERS_DATA":
      if (state) {
        if (action.data.data.length > 0) {
          const array = clone(state.data).concat(action.data.data);
          const result = [];
          const map = new Map();
          for (const item of array) {
            if (!map.has(item.id)) {
              map.set(item.id, true); // set any value to Map
              result.push(item);
            }
          }
          return {
            data: result,
            meta: action.data.meta,
            page: action.data.page,
            where: action.data.where,
          };
        } else if (action.data.page > 0) {
          return { ...state, meta: action.data.meta, page: action.data.page };
        }
        const uniquePhotos = [];
        const mapPhotos = new Map();
        for (const itemPhotos of action.data.data) {
          if (!mapPhotos.has(itemPhotos.id)) {
            mapPhotos.set(itemPhotos.id, true); // set any value to Map
            uniquePhotos.push(itemPhotos);
          }
        }
        return { ...action.data, data: uniquePhotos };
      } else if (action.data) {
        const uniquePhotos2 = [];
        const mapPhotos2 = new Map();
        for (const itemPhotos2 of action.data.data) {
          if (!mapPhotos2.has(itemPhotos2.id)) {
            mapPhotos2.set(itemPhotos2.id, true); // set any value to Map
            uniquePhotos2.push(itemPhotos2);
          }
        }
        return { ...action.data, data: uniquePhotos2 };
      }
      return state;
    case "FEED_FLYERS_DATA_RESET":
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

const defaultDataFlyersSettings = {
  new: {
    retailers_selected: [],
    categories_selected: [],
    countries_selected: [],
    brands_selected: [],
    text_selected: null,
    front_page_only: false,
    dates_selected: null,
  },
  existing: {
    retailers_selected: [],
    categories_selected: [],
    countries_selected: [],
    brands_selected: [],
    text_selected: null,
    front_page_only: false,
    dates_selected: null,
  },
  showDatePicker: false,
  sort: "newest",
};

export const dataFlyersSettings = (
  state = defaultDataFlyersSettings,
  action
) => {
  switch (action.type) {
    case "APPLY_PREFETCHED_FLYERS":
      return {
        ...state,
        prefetchedData: null,
      };
    case "FEED_FLYERS_FILTERS_RESET":
      return {
        ...defaultDataFlyersSettings,
        retailer_options: state.retailer_options,
        retailer_options_last_updated: state.retailer_options_last_updated,
      };
    case "INIT_DEFAULT_ADJACENT_CATEGORY":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          adjacencies: action.data,
        },
      };
    case "FLYERS_SET_ADJACENT_CATEGORY":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          adjacencies: action.data,
        },
      };
    case "FLYER_SETTINGS_CUSTOM":
      return {
        ...state,
        new: {
          ...state.new,
          ...action.data,
        },
        existing: {
          ...state.existing,
          ...action.data,
        },
      };
    case "FLYER_SETTINGS_LP_CATEGORY":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          categories_selected: action.data,
          countries_selected: [],
          products_selected: [],
          brands_selected: [],
          retailers_selected: [],
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          categories_selected: action.data,
          countries_selected: [],
          products_selected: [],
          brands_selected: [],
          retailers_selected: [],
        },
      };
    case "FLYER_SETTINGS_LP_RETAILER":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          brands_selected: [],
          products_selected: [],
          categories_selected: [],
          countries_selected: [],
          retailers_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          brands_selected: [],
          products_selected: [],
          categories_selected: [],
          countries_selected: [],
          retailers_selected: action.data,
        },
      };
    case "FLYER_SETTINGS_LP_BRAND":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          products_selected: [],
          brands_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          products_selected: [],
          brands_selected: action.data,
        },
      };
    case "FLYER_SETTINGS_LP_PRODUCT":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          brands_selected: [],
          products_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          countries_selected: [],
          brands_selected: [],
          products_selected: action.data,
        },
      };
    case "FLYER_SETTINGS_LP_COUNTRY":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          products_selected: [],
          brands_selected: [],
          countries_selected: action.data,
        },
        existing: {
          ...state.existing,
          front_page_only: false,
          retailers_selected: [],
          categories_selected: [],
          products_selected: [],
          brands_selected: [],
          countries_selected: action.data,
        },
      };
    case "SET_FLYER_RULES_COUNTRIES":
      return {
        ...state,
        new: {
          ...state.new,
          country: action.data.value,
        },
      };
    case "SET_FLYER_RULES_RETAILERS":
      return {
        ...state,
        new: {
          ...state.new,
          retailers_selected: action.data,
        },
      };
    case "SET_FLYER_RULES_CATEGORIES":
      return {
        ...state,
        new: {
          ...state.new,
          categories_selected: action.data,
        },
      };
    case "SET_FLYER_RULES_BRANDS":
      return {
        ...state,
        new: {
          ...state.new,
          brands_selected: action.data,
        },
      };
    case "FLYER_FILTERS_PAGE_ANY":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: false,
        },
      };
    case "FLYER_FILTERS_PAGE_FRONT":
      return {
        ...state,
        new: {
          ...state.new,
          front_page_only: true,
        },
      };
    case "FLYER_ANALYTICS_TIMELINE_CHART":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          timeline: { chart: true },
        },
      };
    case "FLYER_ANALYTICS_TIMELINE_TABLE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          timeline: { chart: false },
        },
      };
    case "FLYER_ANALYTICS_CATEGORY_SHARE_FRONT_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          category_share: { front_page_only: true },
        },
      };
    case "FLYER_ANALYTICS_PRESENCE_SHARE_FRONT_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          presence: { front_page_only: true },
        },
      };
    case "FLYER_ANALYTICS_PRESENCE_ANY_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          presence: { front_page_only: false },
        },
      };
    case "FLYER_ANALYTICS_CATEGORY_SHARE_ANY_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          category_share: { front_page_only: false },
        },
      };
    case "FLYER_ANALYTICS_BRAND_SHARE_FRONT_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          brand_share: { front_page_only: true },
        },
      };
    case "FLYER_ANALYTICS_BRAND_SHARE_ANY_PAGE":
      return {
        ...state,
        analytics: {
          ...state.analytics,
          brand_share: { front_page_only: false },
        },
      };
    case "SET_FLYERS_TEXT_FILTER":
      return { ...state, new: { ...state.new, text_selected: action.data } };
    case "SET_FLYERS_TEXT_FILTER_ON_LOAD":
      return {
        ...defaultDataFlyersSettings,
        retailer_options: state.retailer_options,
        retailer_options_last_updated: state.retailer_options_last_updated,
        new: {
          ...defaultDataFlyersSettings.new,
          text_selected: action.data,
        },
        existing: {
          ...defaultDataFlyersSettings.existing,
          text_selected: action.data,
        },
      };
    case "SHOW_FLYER_DATE_PICKER":
      if (
        state.dates_selected &&
        state.dates_selected.start_date &&
        !state.dates_selected.end_date
      ) {
        return { ...state, showDatePicker: true, dates_selected: null };
      }
      return { ...state, showDatePicker: true };
    case "HIDE_FLYER_DATE_PICKER":
      return { ...state, showDatePicker: false };
    case "RESET_FLYERS_DATES_MODAL":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: null,
        },
      };
    case "RESET_FLYERS_DATES":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: null,
        },
        existing: {
          ...state.existing,
          dates_selected: null,
        },
      };
    case "FLYERS_SELECT_DATE_MODAL":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: action.data,
        },
      };
    case "FLYERS_SELECT_DATE":
      return {
        ...state,
        new: {
          ...state.new,
          dates_selected: action.data,
        },
        existing: {
          ...state.new,
          dates_selected: action.data,
        },
      };
    case "FLYER_FILTERS_GET_CATEGORY_DATA":
      return {
        ...state,
        category_options: action.data,
        category_options_last_updated: new Date(),
      };
    case "FLYER_FILTERS_GET_RETAILER_DATA":
      return {
        ...state,
        retailer_options: action.data,
        retailer_options_last_updated: new Date(),
      };
    case "FLYER_FILTERS_APPLY_NEW_CHANGES":
      const newChanges = cloneDeep(state.new);
      return Object.assign({}, state, {
        existing: newChanges,
      });
    case "FLYERS_FILTERS_CHANGE_SORT":
      return { ...state, sort: action.data };
    case "FILTERS_CHECK_RESULTS_COUNT_FLYERS_DATA":
      const uniquePhotosCheck = [];
      const mapPhotosCheck = new Map();
      for (const itemPhotos of action.data.data) {
        if (!mapPhotosCheck.has(itemPhotos.id)) {
          mapPhotosCheck.set(itemPhotos.id, true); // set any value to Map
          uniquePhotosCheck.push(itemPhotos);
        }
      }
      return {
        ...state,
        prefetchedData: { ...action.data, data: uniquePhotosCheck },
      };
    case "FLYER_SELECT_RETAILERS":
      return {
        ...state,
        new: { ...state.new, retailers_selected: action.data },
      };
    case "FLYER_SELECT_CATEGORIES":
      return {
        ...state,
        new: { ...state.new, categories_selected: action.data },
      };
    case "FLYERS_FILTERS_RESET":
    case "LOGOUT":
      return defaultDataFlyersSettings;
    default:
      return state;
  }
};

export const industryReports = (state = {}, action) => {
  switch (action.type) {
    case "PUBLICATIONS_SET_SORT":
    case "PUBLICATIONS_RESET":
      return {};
    case "PUBLISHERS_INCREMENT_PAGE":
      return {
        ...state,
        page: state.page + 1,
      };
    case "INDUSTRY_REPORTS_DATA":
      return { data: action.data, date: new Date() };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export const industryReportsTopics = (state = {}, action) => {
  switch (action.type) {
    case "PUBLICATIONS_RESET":
      return {};
    case "PUBLICATIONS_SEARCH_FOCUS":
      return {
        ...state,
        showSearch: true,
      };
    case "SHOW_MODAL":
    case "SHOW_SEARCH":
    case "HIDE_SEARCH":
    case "MAIN_MENU_HIDE":
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
    case "MAIN_MENU_SHOW_USER":
    case "PUBLICATIONS_SEARCH_BLUR":
      return {
        ...state,
        showSearch: false,
      };
    case "PUBLICATIONS_SET_QUERY":
      return {
        ...state,
        query: action.data,
      };
    case "INDUSTRY_REPORTS_LIST_TOPICS_DATA":
      return {
        ...state,
        date: new Date(),
        data:
          action.data &&
          action.data.length > 0 &&
          action.data.map((x) => x.name),
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export const mapZoom = (state = 5, action) => {
  switch (action.type) {
    case "LOCATIONS_SET_SPECIFIC_ZOOM":
      return action.data;
    case "LOCATIONS_MAP_ZOOM_IN":
      return state + 1;
    case "LOCATIONS_MAP_ZOOM_OUT":
      if (state > 3) {
        return state - 1;
      }
      return state;
    case "LOGOUT":
      return 5;
    default:
      return state;
  }
};

export const dataFlyersAnalytics = (state = {}, action) => {
  switch (action.type) {
    case "FLYERS_ANALYTICS_GET_TIMELINE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          timeline: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_ADJACENCIES_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          adjacencies: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_PRESENCE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          presence: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_BRAND_SHARE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          brand_share: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_CATEGORY_SHARE_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          category_share: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_THEMES_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          themes: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "FLYERS_ANALYTICS_GET_WEBSITES_DATA":
      if (action.data && !action.data.error) {
        return {
          ...state,
          websites: { data: action.data, last_updated: new Date() },
        };
      }
      return state;
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};
export const loginData = (state = {}, action) => {
  switch (action.type) {
    case "ERROR_LOADING_COMPANY_IMG":
      return { error: true };
    case "CHECK_SSO_TLD_AND_GET_EMAIL_CODE_DATA":
      if (Array.isArray(action.data)) {
        return {
          login_tld: action.data[0],
        };
      }
      return state;
    default:
      return state;
  }
};
export const userPreferences = (state = {}, action) => {
  switch (action.type) {
    case "SHOW_FREE_PLAN_BANNER":
      return {
        ...state,
        heroBanner: true,
        lastShownFreeBanner: new Date(),
      };
    case "HIDE_FREE_PLAN_BANNER":
      return { ...state, heroBanner: false };
    case "SHOW_FULLSCREEN_SLIDESHOW":
      return { ...state, fullscreenSlideshow: true };
    case "@@router/LOCATION_CHANGE":
    case "HIDE_FULLSCREEN_SLIDESHOW":
      return { ...state, fullscreenSlideshow: false };
    case "USER_SET_HOVERING_ID":
      return { ...state, hoveringId: action.data };
    case "FEED_FLYERS_SET_KEY":
      return {
        ...state,
        flyerKey: `flyer-key-${new Date().getTime()}`,
      };
    case "FEED_PLANOGRAMS_SET_KEY":
      return {
        ...state,
        planogramKey: `planogram-key-${new Date().getTime()}`,
      };
    case "FEED_PHOTOS_SET_KEY":
      return {
        ...state,
        feedKey: `feed-key-${new Date().getTime()}`,
      };
    case "STAR_BUTTON_CHECK_OFF":
    case "STAR_CHECK_ON":
      return {
        ...state,
        starKey: `star-key-${new Date().getTime()}`,
      };
    case "CLEAR_ENTERPRISE_LOGO_IN_MENU": {
      return {
        ...state,
        current_pc: {},
      };
    }
    case "SWITCH_PRIVATE_SPACE":
      return {
        ...state,
        current_pc: action.data,
      };
    case "PRIVATE_COLLECTION_DATA":
      const currentPc = state.current_pc;
      return {
        ...state,
        current_pc:
          action.data && action.data.length === 0
            ? null
            : action.data && action.data.length === 1
            ? action.data[0]
            : currentPc,
        suspended:
          action.data &&
          action.data.length > 0 &&
          action.data.filter((x) => x.suspended),
      };
    case "PRIVATE_COLLECTION_DASHBOARD_FOR_MENU_DATA":
      return {
        ...state,
        current_pc: {
          ...state.current_pc,
          dashboard: action.data,
        },
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export const dataCampaignsSettings = (state = {}, action) => {
  switch (action.type) {
    case "CAMPAIGNS_SEARCH_FOCUS":
      return {
        ...state,
        showSearch: true,
      };
    case "CAMPAIGNS_RESET":
    case "SHOW_MODAL":
    case "SHOW_SEARCH":
    case "HIDE_SEARCH":
    case "MAIN_MENU_HIDE":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
    case "MAIN_MENU_SHOW_USER":
    case "CAMPAIGNS_SEARCH_BLUR":
      return {
        ...state,
        showSearch: false,
      };
    case "CAMPAIGNS_SET_QUERY":
      return {
        ...state,
        query: action.data,
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export const dataCampaigns = (state = {}, action) => {
  switch (action.type) {
    case "LOGOUT":
    case "CAMPAIGNS_RESET":
      return {};
    case "CAMPAIGNS_DATA":
      return {
        date: new Date().toString(),
        data: action.data,
      };
    default:
      return state;
  }
};

const defaultTrends = { focused: false, keywords: [{ query: "" }] };

export const trends = (state = defaultTrends, action) => {
  switch (action.type) {
    case "TRENDS_SEARCH_RESET":
      return defaultTrends;
    case "TRENDS_SEARCH_FOCUS":
      return {
        ...state,
        focused: true,
      };
    case "TRENDS_SEARCH_BLUR":
      return {
        ...state,
        focused: false,
      };
    case "TRENDS_ADD_KEYWORD": {
      return {
        ...state,
        keywords: state.keywords.concat({ query: "" }),
      };
    }
    case "TRENDS_REMOVE_KEYWORD":
      return {
        ...state,
        keywords: state.keywords.filter((x, i) => i !== action.data),
      };
    case "TRENDS_SET_KEYWORD":
      const newKeywords = cloneDeep(state.keywords);
      newKeywords[action.data.index] = { query: action.data.value };
      return {
        ...state,
        keywords: newKeywords,
      };
    case "TRENDS_GET_LINE_DATA":
      const keywordsData = cloneDeep(state.keywords);
      keywordsData[action.data.keyword_index]["data"] = action.data.data;
      return {
        ...state,
        keywords: keywordsData,
        sample_size: action.data.sample_size,
      };
    case "TRENDS_GET_KEYWORD_VISUALS_DATA":
      const withVisuals = cloneDeep(state.keywords);
      withVisuals[action.data.keyword_index]["visuals"] = action.data.data;
      return {
        ...state,
        keywords: withVisuals,
      };
    default:
      return state;
  }
};

export const showSideMenu = (state = false, action) => {
  switch (action.type) {
    case "HIDE_SIDE_MENU":
    case "SHOW_SEARCH":
    case "MAIN_MENU_SHOW_NOTIFICATIONS":
    case "MAIN_MENU_SHOW_HELP":
    case "MAIN_MENU_SHOW_PC_DASHBOARD":
    case "MAIN_MENU_SHOW_USER":
    case "LOGOUT":
      return false;
    case "SHOW_SIDE_MENU":
      return true;
    default:
      return state;
  }
};

const defaultStudioSettings = {
  stagePosition: { x: 0, y: 0 },
  panningOffset: { x: 0, y: 0 },
  showSearch: false,
  showDropArea: false,
  selectedColor: "#3CD55D",
  selectedShape: null,
  retailer: null,
  lastDownloaded: null,
  pendingShape: null,
  searchResults: null,
  showAdjustMenu: false,
  cropping: false,
  showColorMenu: false,
  stagedFacing: null,
  cursorAuto: false,
  panning: false,
  strokeIds: [],
  targets: [],
  zoom: 1,
};

export const studioSettings = (state = defaultStudioSettings, action) => {
  switch (action.type) {
    case "STUDIO_SET_PANNING_OFFSET":
      return {
        ...state,
        panningOffset: action.data,
      };
    case "STUDIO_SELECT_SHAPE_TEXT":
      return {
        ...state,
        panning: false,
        selectedShape: "text",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE_RECTANGLE":
      return {
        ...state,
        panning: false,
        selectedShape: "rectangle",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE_ARROW":
      return {
        ...state,
        panning: false,
        selectedShape: "arrow",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE_CIRCLE":
      return {
        ...state,
        panning: false,
        selectedShape: "circle",

        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
        })),
      };
    case "STUDIO_CHANGE_COLOR":
      return {
        ...state,
        selectedColor: action.data,
      };
    case "STUDIO_PERSIST_TEXT":
      const pendingShapeText = state.pendingShape;
      return {
        ...state,
        selectedShape: null,
        pendingShape: null,
        targets: state.targets.concat([
          { ...pendingShapeText, text: action.data },
        ]),
      };
    case "STUDIO_PERSIST_SHAPE_TARGET":
      const currentTargets = state.targets;
      return {
        ...state,
        selectedShape: null,
        pendingShape: null,
        targets: currentTargets.concat([action.data]),
      };
    case "STUDIO_DRAW_SHAPE_TARGET":
      return {
        ...state,
        pendingShape: {
          ...state.pendingShape,
          width: action.data.width,
          height: action.data.height,
        },
      };
    case "STUDIO_SHOW_COLOR_MENU":
      return {
        ...state,
        panning: false,
        cropping: false,
        showColorMenu: true,
        showAdjustMenu: false,
      };
    case "STUDIO_START_PANNING":
      return {
        ...state,
        showColorMenu: false,
        selectedShape: false,
        panning: true,
        targets: state.targets.map((x) => ({
          ...x,
          strokeEnabled: false,
          selected: false,
        })),
      };
    case "STUDIO_STOP_PANNING":
      return {
        ...state,
        panning: false,
      };
    case "STUDIO_SET_CURSOR_AUTO":
      return {
        ...state,
        cursorAuto: true,
      };
    case "STUDIO_RESET_CURSOR":
      return {
        ...state,
        cursorAuto: false,
      };
    case "STUDIO_SET_INITIAL_PHOTOS":
      return {
        ...state,
        zoom: action.data.zoom,
        targets: action.data.targets,
      };
    case "STUDIO_DROP_FACING":
      return {
        ...state,
        targets: state.targets.concat([
          {
            x: action.data.x,
            y: action.data.y,
            height: 100,
            width: 100 * action.data.ratio,
            showAnimation: true,
            source: "crops",
            img: action.data.img_facing,
            id: `crop-${Math.floor(Math.random() * 100)}-${Math.floor(
              Math.random() * 100
            )}-${new Date().getMilliseconds()}`,
          },
        ]),
        stagedFacing: null,
      };
    case "STUDIO_HIDE_DROP_AREA":
      return {
        ...state,
        showDropArea: false,
      };
    case "STUDIO_STAGE_FACING_FOR_DROP":
      return {
        ...state,
        showDropArea: true,
        stagedFacing: action.data,
      };
    case "STUDIO_DELETE_TARGET":
      return {
        ...state,
        strokeIds: state.strokeIds.filter((x) => x !== action.data),
        targets: state.targets.filter((x) => !action.data.includes(x.id)),
      };
    case "STUDIO_UPDATE_TARGET": {
      const targets = state.targets.map((target) => {
        if (target.id !== action.data.id) {
          return target;
        }
        return {
          ...target,
          ...action.data.attrs,
        };
      });
      return {
        ...state,
        targets,
      };
    }
    case "STUDIO_ADD_SHAPE_TARGET":
      return {
        ...state,
        pendingShape: action.data,
      };
    case "STUDIO_ADD_STROKE":
      return {
        ...state,
        showColorMenu: false,
        showAdjustMenu: false,
        targets: state.targets.map((x) => ({
          ...x,
          selected: false,
          highlighted: action.data.includes(x.id) ? true : x.highlighted,
        })),
      };
    case "STUDIO_REMOVE_STROKE":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          highlighted: action.data.includes(x.id) ? false : x.highlighted,
        })),
      };
    case "STUDIO_DOWNLOAD_CANVAS_AS_IMAGE":
      return {
        ...state,
        lastDownloaded: `image-download-${new Date()}`,
      };
    case "STUDIO_SHOW_SEARCH":
      return {
        ...state,
        showSearch: true,
      };
    case "STUDIO_HIDE_SEARCH":
      return {
        ...state,
        searchResults: null,
        showSearch: false,
      };
    case "STUDO_SEARCH_FACINGS_DATA":
      return {
        ...state,
        searchResults: action.data,
      };
    case "STUDIO_ZOOM_IN":
      const currentZoomIn = state.zoom;
      return {
        ...state,
        zoom: currentZoomIn + 0.1,
      };
    case "STUDIO_ZOOM_OUT":
      const currentZoomOut = state.zoom;
      return {
        ...state,
        zoom: currentZoomOut - 0.1,
      };
    case "STUDIO_RESET_MENU_SELECT":
      return {
        ...state,
        cropping: false,
        panning: false,
        showColorMenu: false,
        showAdjustMenu: false,
        selectedShape: null,
      };
    case "STUDIO_SELECT_TOOL_CROP":
      return {
        ...state,
        showColorMenu: false,
        showAdjustMenu: false,
        cropping: true,
      };
    case "STUDO_SEARCH_FACINGS_DATA_RESET":
      return {
        ...state,
        searchResults: null,
      };
    case "STUDIO_RESET_SELECT":
      return {
        ...state,
        cropping: false,
        targets: state.targets.map((x) => ({
          ...x,
          strokeEnabled: false,
          selected: false,
        })),
      };
    case "STUDIO_SELECT_SHAPE":
      return {
        ...state,
        showColorMenu: false,
        showAdjustMenu: false,

        targets: state.targets.map((x) => ({
          ...x,
          selected: x.id === action.data ? true : false,
        })),
      };
    case "STUDIO_SHOW_ADJUST_MENU":
      return {
        ...state,
        showAdjustMenu: true,
        cropping: false,
        showColorMenu: false,
      };
    case "STUDIO_SET_RETAILER":
      return {
        ...state,
        retailer: action.data,
      };
    case "STUDIO_ADJUST_BRIGHTNESS_MINUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          brightness:
            action.data === x.id && x.brightness > -1
              ? x.brightness - 0.1
              : x.brightness,
        })),
      };
    case "STUDIO_ADJUST_BRIGHTNESS_PLUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          brightness:
            action.data === x.id && x.brightness < 1
              ? x.brightness + 0.1
              : x.brightness,
        })),
      };
    case "STUDIO_ADJUST_CONTRAST_MINUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          contrast:
            action.data === x.id && x.contrast > -25
              ? x.contrast - 5
              : x.contrast,
        })),
      };
    case "STUDIO_ADJUST_CONTRAST_PLUS":
      return {
        ...state,
        targets: state.targets.map((x) => ({
          ...x,
          contrast:
            action.data === x.id && x.contrast < 90
              ? x.contrast + 5
              : x.contrast,
        })),
      };
    case "VIEWER_SHOW_DETAILS":
    case "HIDE_VIEWER":
    case "LOGOUT":
      return defaultStudioSettings;
    default:
      return state;
  }
};

export const hawkeye = (
  state = { fullscreen: false, selected: [], deletedManually: true },
  action
) => {
  switch (action.type) {
    case "HAWKEYE_APPROVE_DATA":
    case "HAWKEYE_REJECT_DATA":
      return {
        ...state,
        data: null,
      };
    case "HAWKEYE_DELETED_PHOTOS_FIRST_DATA":
      return {
        ...state,
        deleted: action.data,
      };
    case "HAWKEYE_DELETED_PHOTOS_DATA":
      const newDeleted = state.deleted.concat(action.data);
      return {
        ...state,
        deleted: newDeleted,
      };
    case "HAWKEYE_SET_DELETED_AUTOMATICALLY":
      return {
        ...state,
        deletedManually: false,
      };
    case "HAWKEYE_SET_DELETED_MANUALLY":
      return {
        ...state,
        deletedManually: true,
      };
    case "HAWKEYE_GET_AGENTS_DATA":
      return {
        ...state,
        agents: action.data,
      };
    case "HAWKEYE_GET_LEADERBOARD_DATA":
      return {
        ...state,
        leaderboard: action.data,
      };
    case "HAWKEYE_GET_QC_DATA":
      return {
        ...state,
        data: action.data.error ? null : action.data,
      };
    case "HAWKEYE_SHOW_FULLSCREEN":
      return {
        ...state,
        fullscreen: action.data,
      };
    case "HAWKEYE_CLOSE_FULLSCREEN":
      return {
        ...state,
        fullscreen: false,
      };
    default:
      return state;
  }
};
