import {
  FaSnowflake,
  FaSun,
  FaCloudRain,
  FaCalendarPlus,
  FaCloudSun,
  FaSlidersH,
  FaRegCalendarAlt,
  FaSortAmountDown,
  FaSortAmountUpAlt,
  FaRegClock,
  FaHistory,
} from "react-icons/fa";

import { Option } from "constants/styled-components/index";

export const emptyArray = [];
export const emptyObject = {};
export const PHOTO_FEED_LIMIT_FREE_PLAN = 80;
export const PHOTO_FEED_LIMIT = 240;
export const MAX_POG = 15;
export const tooltipDelay = 450;
export const tooltipDelayLong = 800;
export const maxUploadSize = 7340032;
export const learningCenterUrl = "https://docs.shelfgram.com/";
export const clearbitUrl = "https://logo.clearbit.com/";
export const minFlyerDate = new Date("2012-02-29");
export const minPhotosDate = new Date("2005-01-01");
export const genericErrorMessage =
  "Sorry, we couldn't find anything matching your filters";
export const isMobileDevice = window.matchMedia("(max-width: 420px)").matches;
export const MIN_FEED_CARD_SIZE = 270;
export const copyright = `© 2020 - ${new Date().getFullYear()}, Shelfgram Inc.`;
export const appleAppUrl =
  "https://apps.apple.com/us/app/shelfgram/id1489053182?ls=1";
export const androidAppUrl =
  "https://play.google.com/store/apps/details?id=com.shelfgram.app&hl=en";
export const isDev = process.env.NODE_ENV == "development";
export const loginCookieName = "shelfgram_token";
export const adminPlanId = 5;
export const qcPlanId = 4;
export const API = isDev
  ? "http://localhost:8090/api/"
  : window.location.host === "dev.shelfgram.com"
  ? "https://api-dev.shelfgram.com/api/"
  : "https://api-prod.shelfgram.com/api/";
export const demoLink = "https://demo.shelfgram.com";
export const newFeaturesRSS = "product-updates.json";
export const companyWebsite = "https://shelfgram.com/";
export const companyWebsiteNoSlash = "https://shelfgram.com";
export const appPrefixLocal = isDev
  ? "http://localhost:3000/"
  : "https://app.shelfgram.com/";
export const appPrefix = "https://app.shelfgram.com/";
export const awsPrefix = "https://cdn.shelfgram.com/";
export const awsPrefixS3 = "https://s3.amazonaws.com/retail-photos/";
export const awsPhotosPrefix = "https://photos.shelfgram.com/";
export const awsPhotosPrefixS3 = "https://s3.amazonaws.com/shelfgram-photos/";
export const wikipediaPrefix = "https://en.wikipedia.org/wiki/";
export const worldPopulation = 7127361454;
export const worldGDP = 117003980189996;
export const clubChannelSlugs = ["c1N21iMY", "RmRU9gV", "iXcbyaH"];
export const breakpointColumnsObj = {
  default: 5,
  1100: 4,
  700: 2,
  500: 1,
};
export const colorPaletteLite = [
  "rgb(142,209,252)",
  "rgb(0,77,207)",
  "rgb(153,0,239)",
  "rgb(183,28,51)",
  "rgb(245,124,0)",
  "rgb(255,210,7)",
  "rgb(247,141,167)",
  "rgb(0,139,2)",
  "rgb(121,85,51)",
  "rgb(27,27,27)",
  "rgb(151,151,151)",
  "rgb(250,250,250)",
];
export const colorPalette = [
  "#ffb6c1",
  "#ff80ed",
  "#e91e63",
  "#B71C1C",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#407294",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#fed766",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#666666",
  "#607d8b",
  "#EEEEEE",
  "#e6e6fa",
  "#333333",
  "#111111",
];
export const presentationTypeToLayout = {
  photos: {
    masterName: "MASTER_SLIDE_WITH_NOTES",
  },
  cards: {
    cards: 3,
    size: 1,
    yOffset: 0,
    masterName: "MASTER_SLIDE_CARDS",
  },
  grid: {
    cards: 10,
    size: 0.6,
    yOffset: -0.4,
    masterName: "MASTER_SLIDE_GRID",
  },
  title: {
    cards: 4,
    size: 0.75,
    yOffset: 0.8,
    masterName: "MASTER_SLIDE_WITH_TITLE",
  },
};
export const TOAST_TRIGGERS = [
  "ALERT_CREATE_DATA",
  "ALERT_DELETE_DATA",
  "ANALYTICS_REPORT_INCLUDE_API_DATA",
  "ANALYTICS_REPORT_EXCLUDE_API_DATA",
  "PUBLICATIONS_SET_SORT_SAVE_DATA",
  "USER_SAVE_FONT_SIZE_DATA",
  "USER_SAVE_CONTRAST_DATA",
  "USER_SET_PPT_LOGO_PREFERENCE_DATA",
  "USER_SET_PPT_COMPRESSION_PREFERENCE_DATA",
  "USER_SELECT_LANGUAGE_SAVE_DATA",
  "COLLECTION_UPLOAD_COVER_DATA",
  "AUTOMATION_RULES_SAVE_TO_API_DATA",
  "COLLECTION_CHANGE_COLORS_DATA",
  "COLLECTION_CHANGE_COVER_DATA",
  "COLLECTION_CHANGE_NAME_DATA",
  "COLLECTION_CHANGE_SUMMARY_DATA",
  "COLLECTION_CREATE_DATA",
  "COLLECTIONS_LINK_PHOTO_DATA",
  "COLLECTIONS_UNLINK_PHOTO_DATA",
  "COMPANY_CATEGORIES_EDIT_DATA",
  "COMPANY_PARENTS_EDIT_DATA",
  "COMPANY_CHANNELS_EDIT_DATA",
  "CREATE_A_COMPANY_DATA",
  "DELETE_DATA",
  "FEED_SET_DEFAULT_SORT_DATA",
  "HAWKEYE_DELETE_DATA",
  "HAWKEYE_APPROVE_DATA",
  "LOCATION_STORE_NUMBER_EDIT_DATA",
  "PUBLICATIONS_SET_SORT_SAVE",
  "PRIVATE_COLLECTION_CHANGE_COLORS_DATA",
  "PRIVATE_COLLECTION_CHANGE_COVER_DATA",
  "PRIVATE_COLLECTION_CHANGE_NAME_DATA",
  "PRIVATE_COLLECTION_CHANGE_PREVIEW_DATA",
  "PRIVATE_COLLECTION_CHANGE_SUMMARY_DATA",
  "PRIVATE_COLLECTION_CHANGE_DOWNGRADE_DATA",
  "REPORT_DATA",
  "SAVE_INCLUDED_CATEGORIES_DATA",
  "SAVE_NEW_PROFILE_PIC_DATA",
  "TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_OFF_DATA",
  "TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_ON_DATA",
  "USER_CHANGE_NAME_DATA",
  "USER_SAVE_CARD_PREFS_DATA",
  "USER_SELECT_HOMEPAGE_PREFERENCE_SAVE_DATA",
  "USER_SET_LAYOUT_PREFERENCE_DATA",
  "USER_TOGGLES_PUBLISHER_STATUS_DATA",
  "WHITELIST_EMAIL_DOMAIN_DATA",
];

export const TOAST_ACTIONS = {
  COLLECTIONS_LINK_PHOTO_DATA: {
    success: true,
    message: "Added to collections",
  },
  TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_OFF_DATA: {
    success: true,
    message: "Updated",
  },
  TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_ON_DATA: {
    success: true,
    message: "Updated",
  },
  COLLECTIONS_UNLINK_PHOTO_DATA: { success: true, message: "Removed" },
  ALERT_CREATE_DATA: { success: true, message: "Alert created" },
  ALERT_DELETE_DATA: { success: true, message: "Alert deleted" },
  COLLECTION_CREATE_DATA: { success: true, message: "Collection created" },
  DELETE_DATA: { success: true, message: "Deleted" },
  REPORT_DATA: { success: true, message: "Photo reported" },
  USER_CHANGE_NAME_DATA: { success: true, message: "Profile saved" },
};

export const userMenu = [
  { slug: "private_spaces", trans: "130", gated: true },
  { slug: "upload_photos", trans: "76", gated: true },
  { slug: "settings", trans: "5" },
];
export const directoryMenu = [
  {
    name: "Channels",
    slug: "/directory/channels",
    trans: "27",
    route: "channel",
    source: "channels",
    page: "channels",
    largeSize: true,
    paginated: false,
  },
  {
    name: "Categories",
    slug: "/directory/categories",
    trans: "42",
    route: "category",
    source: "categories",
    page: "categories",
    largeSize: true,
    paginated: false,
  },
  {
    name: "Retailers",
    slug: "/directory/retailers",
    trans: "73",
    route: "company",
    source: "companies",
    page: "retailers",
    largeSize: false,
    paginated: true,
  },
  {
    name: "Brands",
    slug: "/directory/brands",
    trans: "41",
    route: "company",
    source: "companies",
    page: "brands",
    largeSize: false,
    paginated: true,
  },
  {
    name: "Tags",
    slug: "/directory/tags",
    trans: "43",
    route: "tag",
    source: "tags",
    page: "tags",
    largeSize: true,
    paginated: false,
  },
  {
    name: "Countries",
    slug: "/directory/countries",
    trans: "71",
    route: "country",
    source: "countries",
    page: "countries",
    largeSize: false,
    paginated: false,
  },
  {
    name: "Regions",
    slug: "/directory/regions",
    trans: "72",
    route: "region",
    source: "regions",
    page: "regions",
    largeSize: true,
    paginated: false,
  },
];
export const pluralToSingular = {
  osa: { lower: "stock levels", upper: "Stock Levels" },
  question_code: { lower: "view", upper: "View" },
  map: { lower: "map", upper: "Map" },
  time_travel: { lower: "time travel", upper: "Time Travel" },
  postal_zip: { lower: "post code", upper: "Post Code" },
  text: { lower: "text", upper: "Text" },
  dates: { lower: "date", upper: "Date" },
  months: { lower: "month", upper: "Month" },
  temperature: { lower: "temperature", upper: "Temperature" },
  weather: { lower: "weather", upper: "Weather" },
  countries: { lower: "country", upper: "Country" },
  channels: { lower: "channel", upper: "Channel" },
  categories: { lower: "category", upper: "Category" },
  collections: { lower: "collection", upper: "Collection" },
  demographics: { lower: "demographic", upper: "Demography" },
  private_collections: {
    lower: "private space",
    upper: "Private Space",
  },
  users: { lower: "user", upper: "User" },
  uploads: { lower: "upload", upper: "Uploads" },
  companies: { lower: "company", upper: "Company" },
  brands: { lower: "brand", upper: "Brand" },
  products: { lower: "product", upper: "Product" },
  retailers: { lower: "retailer", upper: "Retailer" },
  tags: { lower: "tag", upper: "Tag" },
  regions: { lower: "region", upper: "Region" },
  locations: { lower: "location", upper: "Location" },
  cities: { lower: "city", upper: "City" },
  states: { lower: "state", upper: "State" },
};

export const SUGGESTION_WHITELIST = [
  "channels",
  "categories",
  "retailers",
  "brands",
  "collections",
  "countries",
  "states",
  "cities",
];
export const MATCH_OPTIONS = [
  {
    group: "Popular",
    options: [
      {
        value: "retailer",
        label: "Retailer",
        plural: "retailers",
        suggest: true,
      },
      {
        value: "brand",
        label: "Brand",
        plural: "brands",
        suggest: true,
      },
      {
        value: "text",
        label: "Text",
        plural: "text",
        suggest: false,
        text: true,
      },
      {
        value: "category",
        label: "Category",
        plural: "categories",
        suggest: true,
      },
      {
        value: "tag",
        label: "Tag",
        plural: "tags",
        suggest: false,
      },
      {
        value: "date",
        label: "Date",
        plural: "dates",
        suggest: false,
      },
    ],
  },
  {
    group: "Geography",
    options: [
      {
        value: "region",
        label: "Region",
        plural: "regions",
        suggest: false,
      },
      {
        value: "country",
        label: "Country",
        plural: "countries",
        suggest: true,
      },
      {
        value: "state",
        label: "State",
        plural: "states",
        suggest: true,
      },
      {
        value: "city",
        label: "City",
        plural: "cities",
        suggest: true,
      },
      {
        value: "demographic",
        label: "Demography",
        plural: "demographics",
        suggest: false,
      },
      {
        value: "postal_zip",
        label: "Post Code",
        plural: "postal_zip",
        suggest: false,
        text: true,
      },
      {
        value: "map",
        label: "Map",
        plural: "map",
        suggest: false,
      },
    ],
  },
  {
    group: "Other",
    options: [
      {
        value: "user",
        label: "User",
        plural: "users",
        suggest: false,
      },
      {
        value: "months",
        label: "Month",
        plural: "months",
        suggest: false,
      },
      {
        value: "location",
        label: "Location",
        plural: "locations",
        suggest: false,
      },
      {
        value: "channel",
        label: "Channel",
        plural: "channels",
        suggest: true,
      },
      {
        value: "time_travel",
        label: "Time Travel",
        plural: "time_travel",
        suggest: false,
      },
      {
        value: "collection",
        label: "Collection",
        plural: "collections",
        suggest: true,
      },
    ],
  },
];
export const monthsOptions = [
  {
    id: 1,
    name: "January",
    value: "January",
    label: "January",
  },
  {
    id: 2,
    name: "February",
    value: "February",
    label: "February",
  },
  {
    id: 3,
    name: "March",
    value: "March",
    label: "March",
  },
  {
    id: 4,
    name: "April",
    value: "April",
    label: "April",
  },
  {
    id: 5,
    name: "May",
    value: "May",
    label: "May",
  },
  {
    id: 6,
    name: "June",
    value: "June",
    label: "June",
  },
  {
    id: 7,
    name: "July",
    value: "July",
    label: "July",
  },
  {
    id: 8,
    name: "August",
    value: "August",
    label: "August",
  },
  {
    id: 9,
    name: "September",
    value: "September",
    label: "September",
  },
  {
    id: 10,
    name: "October",
    value: "October",
    label: "October",
  },
  {
    id: 11,
    name: "November",
    value: "November",
    label: "November",
  },
  {
    id: 12,
    name: "December",
    value: "December",
    label: "December",
  },
];
export const POWERPOINT_CONSTANTS = {
  CARD_SPACING: 0.3,
  GLOBAL_FONT: "Helvetica",
  CARD_WIDTH_FACTOR: 3.2,
};
export let pptxFooterObjects = [
  {
    rect: {
      x: -0.01,
      y: 5.255,
      w: 10,
      h: 0.37,
      fill: "5E6977",
    },
  },
  {
    rect: {
      x: 8,
      y: 5.255,
      w: 2.01,
      h: 0.37,
      fill: "FFFFFF",
    },
  },
  {
    rect: {
      x: 8.6,
      y: 4.9,
      w: 0.6,
      h: 0.6,
      rotate: 45,
      fill: "E1E8EE",
    },
  },
  {
    rect: {
      x: 8.6,
      y: 5.255,
      w: 0.3,
      h: 0.37,
      fill: "E1E8EE",
    },
  },
  {
    rect: {
      x: 8.3,
      y: 4.9,
      w: 0.6,
      h: 0.6,
      rotate: 45,
      fill: "BDC6CF",
    },
  },
  {
    rect: {
      x: 8.3,
      y: 5.255,
      w: 0.3,
      h: 0.37,
      fill: "BDC6CF",
    },
  },
  {
    rect: {
      x: 8,
      y: 4.9,
      w: 0.6,
      h: 0.6,
      rotate: 45,
      fill: "86939E",
    },
  },
  {
    rect: {
      x: 8,
      y: 5.255,
      w: 0.3,
      h: 0.37,
      fill: "86939E",
    },
  },
  {
    rect: {
      x: 7.7,
      y: 4.9,
      w: 0.6,
      h: 0.6,
      rotate: 45,
      fill: "5E6977",
    },
  },
  {
    rect: {
      x: 0,
      y: 0,
      w: "100%",
      h: 5.255,
      fill: "F0F5F9",
    },
  },
];
export const matchMinWidthMap = {
  osa: "14em",
  demographics: "14em",
  postal_zip: "14em",
  private_collections: "15em",
  temperature: "13em",
};
export const pageToKey = {
  tag: "tags",
  text: "text",
  related: "related",
  demography: "demographics",
  category: "categories",
  channel: "channels",
  country: "countries",
  product: "products",
  company: "companies",
  location: "locations",
  city: "cities",
  state: "states",
  region: "regions",
  collection: "collections",
  upload: "uploads",
  private_collection: "private_collections",
};
export const asyncSelectors = [
  "cities",
  "locations",
  "companies",
  "users",
  "brands",
  "retailers",
  "states",
  "collections",
  "private_collections",
];
export const useCompaniesS3Key = [
  "companies",
  "brands",
  "retailers",
  "private_collections",
  "locations",
];
export const numToLetterMap = {
  0: "a",
  1: "b",
  2: "c",
  3: "d",
  4: "e",
  5: "f",
  6: "g",
  7: "h",
  8: "i",
  9: "j",
};
export const lettersToNumberMap = {
  a: "0",
  b: "1",
  c: "2",
  d: "3",
  e: "4",
  f: "5",
  g: "6",
  h: "7",
  i: "8",
  j: "9",
};
export const mainSectionTag = {
  name: "Main Section",
  img: "82ad31b1-8416-50a4-844b-16e01e6c5156",
  id: "V5ikpTzI",
  url_slug: "V5ikpTzI",
};
export const photoTagTypes = [
  mainSectionTag,
  {
    name: "Displays",
    img: "5f2ed5d5-7370-556d-9697-77b0bc90dad7",
    id: "U_9aCZEN",
  },
];
export const selectors = [
  {
    name: "countries",
    route: "country",
    async: false,
  },
  {
    name: "channels",
    route: "channel",
    async: false,
  },
  {
    name: "categories",
    route: "category",
    async: false,
  },
  {
    name: "brands",
    route: "company",
    async: true,
  },
  {
    name: "retailers",
    route: "company",
    async: true,
  },
  {
    name: "locations",
    route: "location",
    async: true,
  },
  {
    name: "tags",
    route: "tag",
    async: false,
  },
];

export const dateOptions = [
  {
    label: "Custom dates",
    options: [
      {
        value: "Custom",
        name: "Custom",
        id: "custom",
        noImg: true,
        type: "dates",
        label: (
          <Option>
            <FaRegCalendarAlt />
            Custom
          </Option>
        ),
      },
    ],
  },
  {
    label: "Preset options",
    options: [
      {
        value: "Today",
        name: "Today",
        id: "today",
        noImg: true,
        type: "dates",
        label: <Option>Today</Option>,
      },
      {
        value: "Yesterday",
        name: "Yesterday",
        noImg: true,
        id: "yesterday",
        type: "dates",
        label: <Option>Yesterday</Option>,
      },
      {
        value: "Last 7 days",
        name: "Last 7 days",
        noImg: true,
        id: "l7",
        type: "dates",
        label: <Option>Last 7 days</Option>,
      },
      {
        value: "Last 30 days",
        name: "Last 30 days",
        noImg: true,
        id: "l30",
        type: "dates",
        label: <Option>Last 30 days</Option>,
      },
      {
        value: "Last 90 days",
        name: "Last 90 days",
        noImg: true,
        id: "l90",
        type: "dates",
        label: <Option>Last 90 days</Option>,
      },
      {
        value: "Last 365 days",
        name: "Last 365 days",
        noImg: true,
        id: "l365",
        type: "dates",
        label: <Option>Last 365 days</Option>,
      },
      {
        value: "Previous 30 days",
        name: "Previous 30 days",
        noImg: true,
        id: "p30",
        type: "dates",
        label: <Option>Previous 30 days</Option>,
      },
      {
        value: "Previous 90 days",
        name: "Previous 90 days",
        noImg: true,
        id: "p90",
        type: "dates",
        label: <Option>Previous 90 days</Option>,
      },
      {
        value: "Previous 365 days",
        name: "Previous 365 days",
        noImg: true,
        id: "p365",
        type: "dates",
        label: <Option>Previous 365 days</Option>,
      },
      {
        value: "Current month",
        name: "Current month",
        noImg: true,
        id: "currentMonth",
        type: "dates",
        label: <Option>Current month</Option>,
      },
      {
        value: "Last month",
        name: "Last month",
        noImg: true,
        id: "lastMonth",
        type: "dates",
        label: <Option>Last month</Option>,
      },
      {
        value: "Year to date",
        name: "Year to date",
        noImg: true,
        id: "ytd",
        type: "dates",
        label: <Option>Year to date</Option>,
      },
    ],
  },
];
export const alertFrequencyIndexMap = {
  2: 0,
  3: 1,
  4: 2,
};
export const sortOptions = {
  relevant: {
    name: "Most revelant",
    tooltip:
      "This only applies a personalization algorithm to the main photo feed and uses Newest everywhere else.",
    displayName: "Sort: relevant first",
    icon: <FaSlidersH />,
  },
  newest: {
    name: "Newest first",
    tooltip: "This sorts photos by the date they were taken.",
    displayName: "Sort: newest first",
    icon: <FaSortAmountDown />,
    planogram: true,
    flyer: true,
  },
  oldest: {
    name: "Oldest first",
    tooltip: "This sorts photos by the date they were taken.",
    displayName: "Sort: oldest first",
    icon: <FaSortAmountUpAlt />,
    planogram: true,
    flyer: true,
  },
  date_added: {
    name: "Date added",
    tooltip: "This sorts photos by the date they were uploaded to Shelfgram.",
    displayName: "Sort: date added",
    icon: <FaCalendarPlus />,
  },
};

export const whitelistedSorts = Object.keys(sortOptions);

export const commonEmojiMap = [
  {
    emoji: "🤔",
    name: "thinking",
    color: "rgba(240,170,209,0.3)",
  },
  {
    emoji: "😊",
    name: "smile",
    color: "rgba(170,200,240,0.3)",
  },
  {
    emoji: "😃",
    name: "happy",
    shortcut: ":)",
    color: "rgba(170,240,209,0.3)",
  },
  {
    emoji: "😍",
    name: "love",
    color: "rgba(100,240,109,0.3)",
  },
  {
    emoji: "😲",
    name: "surprised",
    shortcut: ":O",
    color: "rgba(170,240,209,0.3)",
  },
  {
    emoji: "😛",
    name: "tongue",
    shortcut: ":P",
    color: "rgba(250,240,9,0.3)",
  },
  {
    emoji: "😂",
    name: "crying",
    shortcut: ":-)",
    color: "rgba(200,100,209,0.3)",
  },
  {
    emoji: "😉",
    name: "wink",
    shortcut: ";)",
    color: "rgba(250,40,90,0.3)",
  },
  {
    emoji: "😎",
    name: "cool",
    color: "rgba(170,0,209,0.3)",
  },
  {
    emoji: "😞",
    name: "sad",
    shortcut: ":(",
    color: "rgba(170,240,0,0.3)",
  },
  {
    emoji: "🙏",
    name: "praise",
    color: "rgba(0,240,209,0.3)",
  },
  {
    emoji: "👌",
    name: "ok",
    color: "rgba(70,40,209,0.3)",
  },
  {
    emoji: "🙌",
    name: "hands",
    color: "rgba(17,240,29,0.3)",
  },
  {
    emoji: "👏",
    name: "clap",
    color: "rgba(10,24,20,0.3)",
  },
  {
    emoji: "👍",
    name: "thumbsup",
    color: "rgba(50,200,209,0.3)",
  },
  {
    emoji: "👎",
    name: "thumbsdown",
    color: "rgba(170,240,100,0.3)",
  },
  {
    emoji: "❤️",
    name: "heart",
    shortcut: "<3",
    color: "rgba(170,200,200,0.3)",
  },
  {
    emoji: "🔥",
    name: "fire",
    color: "rgba(250,100,100,0.3)",
  },
  {
    emoji: "🎉",
    name: "party",
    color: "rgba(170,240,109,0.3)",
  },
  {
    emoji: "💯",
    name: "100",
    color: "rgba(170,140,209,0.3)",
  },
  {
    emoji: "✅",
    name: "check",
    color: "rgba(100,200,209,0.3)",
  },
  {
    emoji: "🏆",
    name: "trophy",
    color: "rgba(250,250,100,0.3)",
  },
  {
    emoji: "💡",
    name: "idea",
    color: "rgba(170,140,159,0.3)",
  },
  {
    emoji: "🛒",
    name: "cart",
    color: "rgba(200,240,259,0.3)",
  },
];
export const styleSmallFont = { fontSize: "0.8em" };
export const styleFlexCenter = { display: "flex", alignItems: "center" };
export const styleFlex = { display: "flex" };
export const tooltipFull = { width: "100%", display: "flex" };
export const fullWidth = { width: "100%" };
export const maxSizeStyle = {
  height: "100%",
  width: "100%",
};
export const inputStyle = {
  width: "100%",
  height: "2.25em",
  padding: "0 1em",
  boxSizing: "border-box",
  border: "none",
  borderRadius: "2em",
};
export const weatherIcons = {
  snow: <FaSnowflake />,
  rain: <FaCloudRain />,
  "clear-day": <FaSun />,
  cloudy: <FaCloudSun />,
};
export const mediaPlaybooks = [
  {
    name: "New Product Launch",
    slug: "MEDIA_BRIEF_NEW_PRODUCT_LAUNCH",
    img: "tom-new-production-launch.png",
    primary_color: "#5f0a87",
    secondary_color: "#a4508b",
    summary:
      "We'll dynamically add locations based on verified sightings of your new product innovation in store, enabling your media investment to scale proportionately with real-time distribution.",
  },
  {
    name: "Omnichannel Prioritization",
    slug: "MEDIA_BRIEF_OMNICHANNEL",
    img: "tom-omnichannel-prioritization.png",
    primary_color: "#a4508b",
    secondary_color: "#a88beb",
    summary:
      "Shelfgram's wide view of retail conditions enables us to leverage your best stores to deliver more sales. Opportunistically allocate your budget between eCommerce and brick-and-mortar for the optimal omnichannel strategy.",
  },
  {
    name: "Competitive Boost",
    slug: "MEDIA_BRIEF_COMPETITIVE_BOOST",
    img: "tom-competitive-boost.png",
    primary_color: "#d5adc8",
    secondary_color: "#861657",
    summary:
      "Your agency does their best to deliver your content efficiently to the target audience. Unfortunately, if your products are out-of-stock and your competitors are on display, your media investment is driving their business instead. We'll let you focus your media around locations where you're strong and your competitors are weak.",
  },
  {
    name: "Continuous Optimization",
    slug: "MEDIA_BRIEF_CONTINUOUS_OPTIMIZATION",
    img: "tom-continuous-optimization.png",
    primary_color: "#861657",
    secondary_color: "#9795ef",
    summary:
      "Our algorithm analyzes distribution, on-shelf availability, display presence, and flyer activity to assign a quality score to every location in your retail network. Turn off ads around unfavourable stores and boost media near locations where your brand is most likely to capture the sale.",
  },
];

export const isOptionAdmin = {
  value: "is none",
  noImg: true,
  disabled: false,
  label: "is none",
  id: "is_none",
};
export const isOptions = [
  {
    value: "is",
    label: "is",
    noImg: true,
    disabled: false,
    id: "is",
  },
  {
    value: "is not",
    noImg: true,
    disabled: false,
    label: "is not",
    id: "is_not",
  },
];
export const isOptionsNumeric = [
  {
    value: "is",
    label: "are >",
    noImg: true,
    disabled: false,
    id: "is",
  },
  {
    value: "is not",
    label: "are <",
    noImg: true,
    disabled: false,
    id: "is_not",
  },
];
export const timeTravelOptions = [
  {
    value: "enabled",
    label: (
      <Option>
        <FaHistory />
        Available
      </Option>
    ),
    name: "Available",
    noImg: true,
    id: "enabled",
  },
  {
    value: "disabled",
    label: (
      <Option>
        <FaRegClock />
        Not yet available
      </Option>
    ),
    name: "Not yet available",
    noImg: true,
    id: "disabled",
  },
];
export const osaOptions = [
  {
    value: 50,
    label: "50%",
    noImg: true,
    disabled: false,
    id: 50,
  },
  {
    value: 55,
    label: "55%",
    noImg: true,
    disabled: false,
    id: 55,
  },
  {
    value: 60,
    label: "60%",
    noImg: true,
    disabled: false,
    id: 60,
  },
  {
    value: 65,
    label: "65%",
    noImg: true,
    disabled: false,
    id: 65,
  },
  {
    value: 70,
    label: "70%",
    noImg: true,
    disabled: false,
    id: 70,
  },
  {
    value: 75,
    label: "75%",
    noImg: true,
    disabled: false,
    id: 75,
  },
  {
    value: 80,
    label: "80%",
    noImg: true,
    disabled: false,
    id: 80,
  },
  {
    value: 85,
    label: "85%",
    noImg: true,
    disabled: false,
    id: 85,
  },
  {
    value: 90,
    label: "90%",
    noImg: true,
    disabled: false,
    id: 90,
  },
  {
    value: 95,
    label: "95%",
    noImg: true,
    disabled: false,
    id: 95,
  },
];
export const dropdownDesktopStyle = {
  marginRight: "0",
  marginLeft: "0.5em",
  height: "1.75em",
  width: "1.75em",
};
export const socialProofLogos = [
  "unilever",
  "ecobeelogo",
  "scotts",
  "jnj",
  "kdrp",
  "np",
  "pharmascience",
  "nestle",
  "smuckers",
  "loreal",
  "righteous",
  "church",
];

export const tools = [
  {
    name: "Photos",
    slug: "/feed",
    img: "photos.png",
    summary: "See what your shoppers are seeing",
  },
  {
    name: "Circulars",
    slug: "/circulars",
    img: "flyers.png",
    summary: "Weekly flyer tracking",
  },
  {
    name: "Planograms",
    enterprise: "enterprise-planograms.png",
    slug_free: "/enterprise/planogram_features",
    slug: "/planograms",
    img: "planograms.png",
    summary: "Walk the entire aisle virtually",
  },
  {
    name: "Price checks",
    enterprise: "enterprise-price-checks.png",
    slug_free: "/enterprise/pricing_features",
    slug: "/price_checks",
    img: "price-checks.png",
    summary: "Fully automated retail price checks",
  },
  {
    name: "Analytics",
    enterprise: "enterprise-analytics.png",
    slug_free: "/enterprise/analytics_features",
    slug: "/analytics",
    img: "analytics.png",
    summary: "Summary retail execution reports",
  },
  {
    name: "Campaigns",
    enterprise: "enterprise-campaigns.png",
    slug_free: "/enterprise/features_campaigns",
    slug: "/campaigns",
    img: "campaigns.png",
    summary: "Omnichannel promos",
  },
];
