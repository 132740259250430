export var questionCodeLinkRows = [
    {
        title: "Categories",
        linkType: "categories",
        route: "category"
    },
    {
        title: "Tags",
        linkType: "tags",
        route: "tag"
    },
    {
        title: "Collections",
        linkType: "collections",
        route: "collection"
    },
    {
        title: "Private Spaces",
        linkType: "private_collections",
        route: "private_collection"
    }
];
export var questionCodeMetadataRows = [
    {
        title: "Data Type",
        source: 'data_products',
        field: "data_product_id"
    },
    {
        title: "Photo Source",
        source: "photo_sources",
        field: "photo_source_id"
    }
];
export var EMPTY_QUESTION_CODE = {
    id: 0,
    name: "",
    photo_source_id: 0,
    data_product_id: 0,
    categories: [],
    tags: [],
    collections: [],
    private_collections: []
};
export var FA_PRIVATE_SPACE = {
    id: "EaKejhOe",
    value: "Field Agent - Retail Pipelines",
    name: "Field Agent - Retail Pipelines",
    img: "5d7eb2cc-9d94-57f3-91fd-21b6e2ff627d",
    source: "companies",
};
